import React from "react";
import { useConfig } from "../../context";
import { Backdrop, CircularProgress } from "@mui/material";

const BackdropSpinner: React.FC<any> = () => {
  const { config } = useConfig();

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={config.showBackdrop ? config.showBackdrop : false}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropSpinner;
