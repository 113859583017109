import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { CreateRule, FetchFlag } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";

import { enqueueSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import ChangeDecisionPages from "../FlagViewRule/ChangeDecisionPages";
import { interaction } from "../Tracking";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "../context";
import { ChangeHandler } from "../FlagViewRule/ChangeHandler";

const CreateExperiment: React.FC<any> = ({
  createDisabled,
  prio,
  flagData,
}) => {
  const { flagId } = useParams();
  const navigate = useNavigate();

  const baseObj = {
    name: "",
    description: "",
    hypothesis: `IF [state the change on the website/app],
to WHO [state who the change is targeted at],
THEN [state the impact on your success metric],
BECAUSE [state the rationale behind this expected change]`,
    flag_id: Number(flagId),
    attributes: [],
    metrics: [],
    meta: {
      locales: [],
      touchpoints: [],
      decision_pages: [],
      version: 4,
      brand: flagData.brand_id,
    },
    variations: [],
    distribution: [],
    decision_page: 0,
    prio: prio,
    type: "select",
  };

  const abVaris = [
    {
      id: 0,
      name: "Control",
      traffic: 50,
      variables: [],
    },
    {
      id: 1,
      name: "Variation #1",
      traffic: 50,
      variables: [],
    },
  ];
  const abDist = [
    {
      variationId: 0,
      endOfRange: 5000,
    },
    {
      variationId: 1,
      endOfRange: 10000,
    },
  ];

  const mabVaris = [
    {
      id: 1,
      name: "Variation #1",
      traffic: 50,
      variables: [],
    },
    {
      id: 2,
      name: "Variation #2",
      traffic: 50,
      variables: [],
    },
  ];
  const mabDist = [
    {
      variationId: 1,
      endOfRange: 5000,
    },
    {
      variationId: 2,
      endOfRange: 10000,
    },
  ];
  const rollVaris = [
    {
      id: 1,
      name: "Variation #1",
      traffic: 100,
      variables: [],
    },
  ];
  const rollDist = [
    {
      variationId: 1,
      endOfRange: 10000,
    },
  ];

  const [newRule, setNewRule] = useState({
    name: "",
    description: "",
    hypothesis: "",
    flag_id: Number(flagId),
    attributes: [],
    metrics: [],
    meta: {
      locales: [],
      touchpoints: [],
      decision_pages: [],
      version: 4,
      brand: flagData.brand_id,
    },
    variations: [],
    distribution: [],
    decision_page: 0,
    prio: prio,
    type: "select",
  });

  (window as any).newRule = newRule;
  const [flagVariables, setFlagVariables] = useState([]);
  const [open, setOpen] = useState(false);

  const { config } = useConfig();

  useEffect(() => {
    FetchFlag((output: ApiResponse) => {
      if (output.status === 200) {
        const obj = output.response[0].variables || [];
        setFlagVariables(obj);
      }
    }, Number(flagId));
  }, [flagId]);

  const onChangeHandler = (e: any) => {
    ChangeHandler(e, setNewRule);
  };

  const onToggleType = (e: any) => {
    const type = e.target.value;
    let variations: any = [];
    const variablesObj = flagVariables.map((vari: any) => {
      return { id: vari.id, value: vari.default_value };
    });
    if (type === "rollout") {
      variations = rollVaris;
      variations[0].variables = variablesObj;
    } else if (type === "ab") {
      variations = abVaris;
      variations[1].variables = variablesObj;
    } else {
      variations = mabVaris;
      variations[0].variables = variablesObj;
      variations[1].variables = variablesObj;
    }
    setNewRule((prevState: any) => ({
      ...prevState,
      type: type,
      variations: variations,
      distribution:
        type === "ab" ? abDist : type === "mab" ? mabDist : rollDist,
    }));
  };

  const submitForm = () => {
    console.log("submitForm", newRule);
    interaction("create_experiment");
    CreateRule((output: ApiResponse) => {
      if (output?.status !== 200) {
        enqueueSnackbar(output.message, { variant: "error" });
      } else {
        const message = `Experiment created`;
        enqueueSnackbar(message, { variant: "success" });
        navigate(`/features/${flagId}/${output.response.insertId}`);
      }
    }, newRule);
  };

  const openDialogHandler = () => {
    setNewRule(baseObj);
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        disabled={createDisabled}
        onClick={openDialogHandler}
      >
        New experiment
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>New experiment</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} justifyContent="stretch">
            <Grid item xs={6}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item xs="auto">
                  <Typography variant="caption">Experiment name</Typography>
                </Grid>
                <Grid item xs={12} paddingTop="3px">
                  <TextField
                    autoFocus
                    margin="dense"
                    value={newRule.name || ""}
                    name="name"
                    label="Experiment name"
                    type="text"
                    fullWidth
                    onChange={onChangeHandler}
                    size="small"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} pt={1}>
              <ChangeDecisionPages
                currentPages={newRule.meta.decision_pages}
                handlePageChange={onChangeHandler}
                editDisabled={false}
                size="small"
              />
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-start"
              direction="row"
            >
              <Grid item container justifyContent="flex-start">
                <Grid item xs="auto">
                  <Typography variant="caption">Type</Typography>
                </Grid>
                <Grid item xs={2}>
                  <HelpToolTip
                    text={config.cms_strings.experiments.type_tooltip}
                    link={config.cms_strings.experiments.type_tooltip_link}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  sx={{ m: 1, minWidth: 120, marginLeft: "0px!important" }}
                  size="small"
                >
                  <Select
                    name="type"
                    defaultValue="select"
                    onChange={onToggleType}
                  >
                    <MenuItem key={0} value="select">
                      Select type
                    </MenuItem>
                    <MenuItem key={1} value="ab">
                      A/B-Test
                    </MenuItem>
                    <MenuItem key={2} value="mab">
                      Multi-armed bandit
                    </MenuItem>
                    <MenuItem
                      key={3}
                      value="rollout"
                      disabled={
                        config.user.level < 50 &&
                        flagData.flag_key !== "pdp_plp_product_discount" &&
                        flagData.flag_key !== "pdp_product_discount"
                      }
                    >
                      Rollout
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={submitForm}
            disabled={
              newRule.meta.decision_pages.length < 1 ||
              newRule.name.length === 0 ||
              newRule.type === "select"
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateExperiment;
