import React from "react";
import { useNavigate } from "react-router-dom";
import { ArchiveRule } from "../helpers/FetchData";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import UndoIcon from "@mui/icons-material/Undo";
const UnArchiveRule: React.FC<any> = ({ ruleId, flagId, type, disabled }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const ruleType =
    type === "mab"
      ? "Multi-armed bandit"
      : type === "rollout"
      ? "Rollout"
      : "A/B-Test";
  const deleteText = `Restore`;
  const submitArchive = () => {
    const obj = {
      archive: false,
    };

    confirm({
      title: `Restore`,
      description: `Are you sure you want to restore this item?`,
      confirmationButtonProps: { color: "error", variant: "contained" },
    })
      .then(() => {
        ArchiveRule(
          (output: any) => {
            if (output?.status !== 200) {
              enqueueSnackbar(output.message, { variant: "error" });
            } else {
              enqueueSnackbar(`${ruleType} restored!`, { variant: "success" });
              navigate(`/features/${flagId}`, {
                state: { refresh: Date.now() },
              });
            }
          },
          Number(ruleId),
          obj
        );
      })
      .catch(() => {
        console.log("Cancelled");
      });
  };

  return (
    <ListItemButton onClick={submitArchive} disabled={disabled}>
      <ListItemIcon>
        <UndoIcon sx={{ color: "red" }} />
      </ListItemIcon>
      <ListItemText sx={{ color: "red" }} primary={deleteText} />
    </ListItemButton>
  );
};

export default UnArchiveRule;
