import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByPage } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import LaunchedByGraph from "./LaunchedByGraph";

const LaunchedByPage: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const { config } = useConfig();
  const [pagesInChart, setPagessInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  useEffect(() => {
    if (config.decision_pages.length > 0 && isLoaded === false) {
      FetchStatsLaunchedByPage((output: any) => {
        setIsLoaded(true);
        const all: any = [];
        const allPages: any = [];
        output.response.forEach((item: any) => {
          const obj = all.find((a: any) => a.month === item.month) || {
            month: item.month,
          };

          obj[item.decision_page] = item.experiments_started;
          if (!allPages.includes(item.decision_page)) {
            allPages.push(item.decision_page);
          }
          const index: number = all.findIndex(
            (a: any) => a.month === item.month
          );
          if (index === -1) {
            all.push(obj);
          } else {
            all[index] = obj;
          }
        });
        setLaunchData(all);
        setPagessInChart(allPages);
        const palette = ColorPalette(allPages.length);
        const colors: any = [];
        allPages.forEach((r: any, idx: number) => {
          colors[r] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config, isLoaded]);

  const getPageName = (decision_page: string) => {
    const region: any = config.decision_pages.find(
      (t: any) => t.id === decision_page
    );
    return region ? region.name : decision_page;
  };
  const tooltipFormatter = (value: any, name: any) => {
    return [value, getPageName(name)];
  };

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{getPageName(value)}</span>;
  };

  return (
    <LaunchedByGraph
      title="Experiments by Page"
      isLoaded={isLoaded}
      data={launchData}
      tooltipFormatter={tooltipFormatter}
      itemsInChart={pagesInChart}
      legendFormatter={legendFormatter}
      chartColors={chartColors}
    />
  );
};

export default LaunchedByPage;
