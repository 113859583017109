import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import moment from "moment";
const TimeBoardTooltip: React.FC<any> = ({ item, mode }) => {
  return (
    <Card
      sx={{ pt: 1, width: "30vw" }}
      className={`watchtower-tooltip ${mode}`}
      id={item.id}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={
            mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "text.secondary"
          }
          component="div"
        >
          {item.brand}
        </Typography>
        <Typography noWrap variant="h5">
          {item.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ display: "inline-block", whiteSpace: "pre-line" }}
        >
          {item.description}
        </Typography>
        <Grid container spacing={1} justifyContent="flex-start" marginTop={1}>
          <Grid item xs="auto">
            <Typography
              variant="body2"
              sx={{ display: "inline-block", whiteSpace: "pre-line" }}
            >
              <strong>Start:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="body2"
              sx={{ display: "inline-block", whiteSpace: "pre-line" }}
            >
              {item.state === 30
                ? moment(item.start_time).format("DD MMM")
                : moment(item.planned_start_date).format("DD MMM")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Typography
              variant="body2"
              sx={{ display: "inline-block", whiteSpace: "pre-line" }}
            >
              <strong>Stop:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="body2"
              sx={{ display: "inline-block", whiteSpace: "pre-line" }}
            >
              {moment(item.planned_end_date).format("DD MMM")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} marginTop={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Typography variant="body2">
              <strong>Touchpoints:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" textTransform="capitalize">
              {item.meta && item.meta.touchpoints
                ? item.meta.touchpoints.join(", ")
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Typography variant="body2">
              <strong>Locales:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">
              {item.meta && item.meta.locales
                ? item.meta.locales.join(", ")
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Typography variant="body2">
              <strong>Developer:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">{item.developer}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Typography variant="body2">
              <strong>Owner:</strong>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">{item.owner}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TimeBoardTooltip;
