import React, { useEffect, useState } from "react";
import { Paper, Stack, Grid, Typography, Button, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import AudienceModal from "./AudienceModal";
import GlobalAudiences from "./GlobalAudiences";
import { useConfig } from "./../context";
import { formatAudienceMatchType } from "./../helpers/Helpers";
const Attributes: React.FC<any> = ({
  ruleAudiences,
  addAttributeHandler,
  removeAdudience,
  attributes,
  editDisabled,
  toggleStore,
  brandId,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [attribute, setAttribute] = useState({
    name: "",
    description: "",
    attribute: "",
    values: [""],
    matchType: "eq",
    index: -1,
  });
  const [isCustom, setIsCustom] = useState(false);
  const [allowedLocales, setAllowedLocales] = useState<string[]>([]);
  const { config } = useConfig();

  useEffect(() => {
    const region = config?.regions?.find(
      (region: any) => region.region_key === config.user.region
    );
    const regionLocales: string =
      region && region["locales"] ? region["locales"] : "";
    const allowedLocales: string[] = regionLocales.split("|");
    if (allowedLocales.length > 0 && allowedLocales[0].length > 0) {
      setAllowedLocales(allowedLocales);
    }
  }, [config]);

  const createAudience = () => {
    setAttribute({
      name: "",
      description: "",
      attribute: "",
      values: [""],
      matchType: "eq",
      index: -1,
    });
    setIsCustom(false);
    setDialogOpen(true);
  };

  const saveAudience = (e: any) => {
    setDialogOpen(false);
    addAttributeHandler(e);
  };

  const updateAudience = (e: any) => {
    const audience = JSON.parse(
      JSON.stringify(ruleAudiences[e.currentTarget.dataset.index])
    );
    audience.index = e.currentTarget.dataset.index;
    setIsCustom(!attributes.some((i: any) => i.name === audience.attribute));
    setAttribute(audience);
    setDialogOpen(true);
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  const isValidLocale = (locale: string) => {
    return allowedLocales.length > 0 ? allowedLocales.includes(locale) : true;
  };

  const checkAllowedLocales = (localestring: string) => {
    const locales = localestring.split("|");
    const validLocales = locales.filter((locale: string) =>
      isValidLocale(locale)
    );
    return validLocales.length === locales.length;
  };

  const disableEditAudience = (item: any) => {
    return (
      item.attribute === "locale" && !checkAllowedLocales(item.values.join("|"))
    );
  };

  const showValues = (item: any) => {
    return item.values.map((i: any, index: number) => (
      <Grid
        item
        xs="auto"
        container
        key={index}
        sx={{ "&:last-child .orchip": { display: "none" } }}
      >
        <Grid item xs="auto">
          <Typography variant="caption">{i}</Typography>
        </Grid>
        <Grid item xs="auto">
          <Chip
            className="orchip"
            size="small"
            label="OR"
            variant="filled"
            sx={{ "& span": { px: "4px" }, mx: "4px" }}
          />
        </Grid>
      </Grid>
    ));
  };

  const attributeItems = () => {
    if (ruleAudiences.length > 0) {
      return ruleAudiences.map((item: any, key: any) => (
        <Grid
          xs="auto"
          item
          container
          key={key}
          justifyContent="flex-start"
          sx={{ "&:last-child .andchip": { display: "none" } }}
        >
          <Grid item key={key}>
            <Paper sx={{ p: 1 }}>
              <Grid container>
                <Grid container item xs>
                  <Grid xs={12} item>
                    <Typography variant="subtitle2" display="inline-block">
                      {item?.name || "Name"}
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-start">
                    <Grid item xs="auto">
                      <Typography variant="caption" sx={{ mr: "3px" }}>
                        {item.attribute}{" "}
                        <strong className="matchType">
                          {formatAudienceMatchType(item.matchType)}
                        </strong>
                      </Typography>
                    </Grid>
                    {showValues(item)}
                  </Grid>
                </Grid>
                <Grid container item xs={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={updateAudience}
                      data-index={key}
                      sx={{ p: 0, justifyContent: "right", minWidth: 0 }}
                      disabled={disableEditAudience(item)}
                    >
                      <CreateIcon />
                    </Button>
                    <Button
                      onClick={removeAdudience}
                      data-index={key}
                      sx={{ p: 0, justifyContent: "right", minWidth: 0 }}
                      disabled={editDisabled}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            sx={{ pl: 2, alignItems: "center", display: "flex" }}
            alignItems="center"
          >
            <Chip
              className="andchip"
              size="small"
              label="AND"
              variant="filled"
              sx={{ "& span": { px: "4px" } }}
            />
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <Grid item key="123321">
          <Paper sx={{ p: 1 }}>
            <Grid container>
              <Grid xs={12} item>
                <Typography variant="subtitle2">No audience added</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h6">Audiences</Typography>
          </Grid>
          <Grid item>
            <GlobalAudiences
              addAudience={addAttributeHandler}
              addCustomAudience={createAudience}
              addDisabled={editDisabled}
              checkAllowedLocales={checkAllowedLocales}
              brandId={brandId}
            ></GlobalAudiences>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2} sx={{ marginTop: "-20px!important" }}>
            {attributeItems()}
          </Grid>
        </div>
      </Stack>
      <AudienceModal
        toggleStore={toggleStore}
        addAudience={saveAudience}
        attribute={attribute}
        dialogOpen={dialogOpen}
        dialogClose={dialogClose}
        editDisabled={editDisabled}
        attributes={attributes}
        isCustom={isCustom}
        checkAllowedLocales={checkAllowedLocales}
        brandId={brandId}
      />
    </>
  );
};

export default Attributes;
