import React, { useState } from "react";
import { Alert, Box, Button, Collapse, Grid, InputLabel } from "@mui/material";

// @ts-ignore
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor/dist/jsoneditor.css";

const CustomCxsSwbJson: React.FC<any> = ({
  onChangeVariables,
  indexes,
  label,
  variable,
}) => {
  let parsedVariable = {};
  if (variable.value.length !== 0) parsedVariable = JSON.parse(variable.value);
  const [jsonData, setJsonData] = useState(parsedVariable);

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  const jsonHandler = (value: any): void => {
    const string = JSON.stringify(value);
    setJsonData(value);

    const fakeEvent = { target: { value: string, type: null } };
    onChangeVariables(fakeEvent, indexes);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="text" onClick={toggleEditorVisibility}>
            {isEditorVisible
              ? "Hide advanced developer editor for APPs json"
              : "Open advanced developer editor for APPs json"}
          </Button>
        </Box>
        <Collapse in={isEditorVisible}>
          <InputLabel>{label}</InputLabel>
          <Alert severity="warning">
            This is the JSON for the APPs. If you make changes above this will
            be updated (You need to refresh the page to see). You can make
            changes here but it will overwritten if you make changes above.
          </Alert>

          <JsonEditor
            value={jsonData}
            onChange={jsonHandler}
            mode="code"
            htmlElementProps={{ style: { height: 400 } }}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default CustomCxsSwbJson;
