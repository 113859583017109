import React, { useState, useEffect } from "react";
import { redirectLogin, signOut } from "../../helpers/Helpers";
import { useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useConfig } from "../../context";
const TokenManager: React.FC<{}> = () => {
  const timeout = 1800000;
  const promptBeforeIdle = 30000;

  const { config } = useConfig();
  const [open, setOpen] = useState(false);
  const [idleState, setIdleState] = useState("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  useEffect(() => {
    if (config.tokenExpired) setOpen(true);
  }, [config]);

  const onIdle = () => {
    setIdleState("Idle");
    signOut();
  };

  const onActive = () => {
    setIdleState("Active");
  };

  const onPrompt = () => {
    setIdleState("Prompted");
    setOpen(true);
  };
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: [
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
    ],
    crossTab: true,
    syncTimers: 200,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
    redirectLogin();
  };
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  (window as any).tokenTimeout = timeTillPrompt;
  (window as any).tokenIdleState = idleState;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Session expiration"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session is about to expire, click ok to refresh the page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleStillHere} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenManager;
