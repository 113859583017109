import React, { useEffect, useState } from "react";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  IconButton,
  FormLabel,
  Chip,
  Stack,
  Divider,
  Link,
  Alert,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import teaserFields from "./teaserFields.js";

const CustomCxsTeaser: React.FC<any> = ({
  onChangeVariables,
  indexes,
  variable,
}) => {
  let pastVariables: any = [];
  let parsedVariable = "";
  if (variable.value.length !== 0) parsedVariable = JSON.parse(variable.value);

  if (parsedVariable) {
    Object.keys(parsedVariable).map((name: any) =>
      teaserFields.find((item: any) => {
        let newItem = { ...item };
        if (newItem.name === name) {
          newItem.value = parsedVariable[name];
          pastVariables.push(newItem);
          return false;
        } else if (name === "ratios") {
          Object.keys(parsedVariable[name][0]).map((objKey: any) => {
            if (newItem.name === objKey) {
              newItem.value = parsedVariable[name][0][objKey];
              pastVariables.push(newItem);
            }
            return false;
          });
        }
        return false;
      })
    );
  }

  const [customVariable, setCustomVariable] = useState<any>(pastVariables);
  const [teaserObj, setTeaserObj] = useState<any>({});

  useEffect(() => {
    // Converts customVariable to teaserObj so it can be used in onChangeVariables
    // This is what gets sent to the backend
    setTeaserObj((prevState: any) => {
      let obj: any = {};
      customVariable.forEach((vari: any) => {
        if (!vari) return null;
        if (vari.belongsTo) {
          // Problem if we gonna add "priceModules" (?)
          if (obj[vari.belongsTo]) {
            // add
            obj[vari.belongsTo][0] = {
              ...obj[vari.belongsTo][0],
              [vari.name]: vari.value,
            };
          } else {
            // create
            obj = { ...obj, [vari.belongsTo]: [{ [vari.name]: vari.value }] };
          }
        } else {
          obj = { ...obj, [vari.name]: vari.value };
        }
      });
      return obj;
    });
  }, [customVariable]);

  useEffect(() => {
    const string = JSON.stringify(teaserObj);
    const fakeEvent = { target: { value: string, type: null } };
    onChangeVariables(fakeEvent, indexes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaserObj]);

  const handler = (index: number, obj?: any) => (event: any) => {
    const { value } = event.target;

    setCustomVariable((prevState: any) => {
      return prevState.map((first: any, pix: number) => {
        if (index !== pix) return first;
        return { ...first, value: value };
      });
    });
  };

  const handleSelectChange = (event: any) => {
    const { value } = event.target;

    const obj = teaserFields.find((item: any) => item.name === value);
    const ifFieldAlreadyExists = customVariable.find(
      (item: any) => item.name === value
    );

    if (ifFieldAlreadyExists && value !== "cta") return;

    if (value === "cta" && ifFieldAlreadyExists) {
      setCustomVariable((prevState: any) => {
        return prevState.map((first: any, pix: number) => {
          if (first.name !== "cta") return first;
          return {
            ...first,
            value: [...first.value, { url: "link", text: "cta text" }],
          };
        });
      });
    } else {
      setCustomVariable((prevState: any) => {
        return [...prevState, obj];
      });
    }
  };

  const ControlPanel = () => {
    return (
      <div>
        <Alert severity="info">
          Please see our{" "}
          <Link
            href="https://hennesandmauritz.sharepoint.com/:u:/r/sites/ExperimentationWebVisualAnalytics/SitePages/How-to-AB-Test-on-Teasers.aspx?csf=1&web=1&e=fOtPGa"
            target="_blank"
            rel="noreferrer"
          >
            sharepoint for more details
          </Link>{" "}
          for what is prerequisite to do in AEM to be able to do AB-Tests on
          Teasers/Banners. Explains what "Tracking promotion creative" is and that "Used
          for AB testing" needs to be checked in AEM.
        </Alert>
        <p>Pick the items below that you want to change:</p>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {teaserFields.map(
            (item: { name: string; label: string; field_type: string }) => {
              const findIfAlreadyInPlace = customVariable.find(
                (find: any) => find.name === item.name
              );
              return (
                <Chip
                  label={item.label}
                  key={item.name}
                  disabled={findIfAlreadyInPlace ? true : false}
                  onClick={(e) =>
                    handleSelectChange({ target: { value: item.name } })
                  }
                />
              );
            }
          )}
        </Stack>
        <Divider sx={{ mt: 2 }} />
        <br />
      </div>
    );
  };

  const DropdownField = ({ variObj, variIndex }: any) => {
    return (
      <Grid item xs={6} key="grid-dropdown-field">
        <FormControl
          sx={{ minWidth: 150 }}
          style={{ width: "calc(100% - 50px)" }}
          margin="dense"
        >
          <InputLabel id="dropdown-select-label">{variObj.label}</InputLabel>
          <Select
            labelId="dropdown-select-label"
            value={variObj.value}
            onChange={handler(variIndex, variObj)}
            label={variObj.label}
          >
            {variObj.options.choices.map(
              (item: { value: string; label: string }) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        <IconButton
          aria-label="delete"
          onClick={(e) => removeField(e, variIndex)}
          style={{ margin: "16px 0 0px 5px" }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const ctaHandler = (e: any, variIndex: number, ctaIndex: number) => {
    const { value, name } = e.target;

    setCustomVariable((prevState: any) => {
      return prevState.map((first: any, pix: number) => {
        if (variIndex !== pix) return first;
        const newValue = first.value.map((cta: any, ctaPix: number) => {
          if (ctaIndex !== ctaPix) return cta;
          return { ...cta, [name]: value };
        });
        return { ...first, value: newValue };
      });
    });
  };

  const removeCta = (event: any, index: number) => {
    const { name } = event.currentTarget;

    // If last one, remove the whole field
    const ctaField = customVariable.filter((item: any) => item.name === name);
    if (ctaField[0].value.length <= 1) {
      setCustomVariable((prevState: any) =>
        prevState.filter((item: any, pix: number) => item.name !== name)
      );
      return;
    }

    const ctaChange = customVariable.map((each: any, pix: number) => {
      if (each.name !== name) return each;
      const newValue = each.value.filter(
        (cta: any, ctaPix: number) => index !== ctaPix
      );
      return { ...each, value: newValue };
    });
    setCustomVariable(ctaChange);
  };

  const removeField = (event: any, index: number) => {
    setCustomVariable((prevState: any) =>
      prevState.filter((item: any, pix: number) => pix !== index)
    );
  };

  const CtaField = ({ variObj, variIndex }: any) => {
    return (
      <Grid item xs={12} key="grid-cta-field">
        <Grid key="cta-field" container spacing={1} sx={{ marginBottom: 1 }}>
          {variObj.value.map((cta: any, ctaIndex: number) => {
            return (
              <Grid item xs={6} key={"cta" + ctaIndex}>
                <FormLabel id="demo-radio-buttons-group-label">
                  CTA {ctaIndex + 1}:
                </FormLabel>
                <br />
                <TextField
                  key={"text" + ctaIndex}
                  label="Text"
                  name="text"
                  value={cta.text || ""}
                  sx={{ minWidth: "150px", marginRight: 1 }}
                  style={{ width: "calc(50% - 30px)" }}
                  margin="dense"
                  onChange={(e) => ctaHandler(e, variIndex, ctaIndex)}
                />
                <TextField
                  key={"url" + ctaIndex}
                  label="Link"
                  name="url"
                  margin="dense"
                  value={cta.url || ""}
                  sx={{ minWidth: "150px" }}
                  style={{ width: "calc(50% - 30px)" }}
                  onChange={(e) => ctaHandler(e, variIndex, ctaIndex)}
                />
                <IconButton
                  aria-label="delete"
                  name="cta"
                  onClick={(e) => removeCta(e, ctaIndex)}
                  style={{ margin: "16px 0 0px 5px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => handleSelectChange({ target: { value: "cta" } })}
            >
              Add CTA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <ControlPanel />
      <Grid key="teaser-fields" container spacing={1} sx={{ marginBottom: 1 }}>
        {customVariable.map((variObj: any, variIndex: number) => {
          if (!variObj) return null;
          switch (variObj.field_type) {
            case "dropdown":
              return (
                <DropdownField
                  key={variObj.name}
                  variObj={variObj}
                  variIndex={variIndex}
                />
              );
            case "cta":
              return CtaField({
                variObj: variObj,
                variIndex: variIndex,
              });
            default:
              return (
                <Grid item xs={6} key={variObj.name + "div"}>
                  <TextField
                    key={variObj.name}
                    label={variObj.label}
                    name={variObj.name}
                    value={variObj.value || ""}
                    sx={{ minWidth: 150 }}
                    style={{ width: "calc(100% - 50px)" }}
                    margin="dense"
                    onChange={handler(variIndex, variObj)}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => removeField(e, variIndex)}
                    style={{ margin: "16px 0 0px 5px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              );
          }
        })}
      </Grid>
    </>
  );
};

export default CustomCxsTeaser;
