import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByTeam } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import LaunchedByGraph from "./LaunchedByGraph";

const LaunchedByTeam: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const { config } = useConfig();
  const [teamsInChart, setTeamsInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  useEffect(() => {
    if (config.teams.length > 0) {
      FetchStatsLaunchedByTeam((output: any) => {
        setIsLoaded(true);
        const all: any = [];
        const allTeams: any = [];
        output.response.forEach((item: any) => {
          const obj = all.find((a: any) => a.month === item.month) || {
            month: item.month,
          };
          const team: any = config.teams.find(
            (t: any) => t.id === item.team_id
          );
          obj[team.team_key] = item.experiments_started;
          if (!allTeams.includes(team.team_key)) {
            allTeams.push(team.team_key);
          }
          const index: number = all.findIndex(
            (a: any) => a.month === item.month
          );
          if (index === -1) {
            all.push(obj);
          } else {
            all[index] = obj;
          }
        });
        setLaunchData(all);
        setTeamsInChart(allTeams);
        const palette = ColorPalette(allTeams.length);
        const colors: any = [];
        allTeams.forEach((team: any, idx: number) => {
          colors[team] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config]);

  const getTeamName = (team_key: string) => {
    const team: any = config.teams.find((t: any) => t.team_key === team_key);
    return team ? team.name : team_key;
  };
  const tooltipFormatter = (value: any, name: any) => {
    return [value, getTeamName(name)];
  };

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{getTeamName(value)}</span>;
  };

  return (
    <LaunchedByGraph
      title="Experiments by Team"
      isLoaded={isLoaded}
      data={launchData}
      tooltipFormatter={tooltipFormatter}
      itemsInChart={teamsInChart}
      legendFormatter={legendFormatter}
      chartColors={chartColors}
    />
  );
};

export default LaunchedByTeam;
