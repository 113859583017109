import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchRule } from "../helpers/FetchData";

const RedirectToFlagViewRule = () => {
  const { ruleId } = useParams();
  const navigate = useNavigate();
  const [hasResponse, setHasResponse] = useState(true);

  useEffect(() => {
    FetchRule((output: any) => {
      if (output.response?.length > 0) {
        let flagId = output.response[0].flag_id;
        navigate(`/features/${flagId}/${ruleId}`);
      } else {
        setHasResponse(false);
      }
    }, Number(ruleId));
  }, [ruleId, navigate]);

  if (!hasResponse) {
    return <div>Experiment not found.</div>;
  }

  return <div>Redirecting...</div>;
};

export default RedirectToFlagViewRule;
