import React from "react";

import {
  Paper,
  Stack,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Tooltip,
  InputAdornment,
  Alert,
  Link,
  Box,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Loader from "../components/common/Loader";
import { useConfig } from "../context";
import CustomCxsTeaser from "./fields/CustomCxsTeaser";
import CustomCxsNsTeaser from "./fields/CustomCxsNsTeaser";
import CustomCxsSiteWideBanner from "./fields/CustomCxsSiteWideBanner";
import CustomCxsSwbJson from "./fields/CustomCxsSwbJson";
import CustomCxsBanner from "./fields/CustomCxsBanner";
import BooleanField from "./fields/BooleanField";
import DatepickerField from "./fields/DatepickerField";
import JsonField from "./fields/JsonField";
import AndroidIosSupport from "./fields/AndroidIosSupport";
import AiText from "./fields/AiText";

const cxsCustomLabels: { [key: string]: string } = {
  // RPV
  discount: "Discount",
  discountRedPrice: "Discount red price",
  discountWhitePrice: "Discount white price",
  discountYellowPrice: "Also discount yellow price",
  excludeByArticle: "Exclude by article-IDs",
  excludeByBrand: "Exclude by brand",
  excludeByMarketingMarker: "Exclude by marketing marker",
  excludeMemberArticle: "Exclude all member articles",

  // PDP Messages
  countdownText: "Countdown text",
  dealMessage: "Deal message",
  exclude_article_ids: "Exclude article-IDs",
  expiresAt: "Expires at",
  link: "Link",
  textColor: "Text color",
  visibility_member_price: "Exclude all member articles", // Was "Visibility member price" - logic reversed in "BooleanField"
  visibility_not_sale: "Display on white price",
  visibility_sale: "Display on red price",

  // Other
  trackingPromotionCreative: "Tracking promotion creative",
  teaserTrackingPromotionCreative: "Tracking promotion creative",
  bannerTrackingPromotionCreative: "Tracking promotion creative",
  text: "Text",
};

const VariationsFields: React.FC<any> = ({
  newVariations,
  onChangeVariation,
  flagVariables,
  onChangeVariables,
  addNewVariations,
  removeVariation,
  stateStatus,
  disableArchive,
  editDisabled,
  flagFetched,
  lastAdded,
  isCreate,
  isMab,
  isRollout,
  meta,
  ruleData,
}) => {
  const { config } = useConfig();

  if (!flagFetched) return <Loader />;
  // Sort flagVariables by sort_order, if null, add last
  flagVariables.sort((a: any, b: any) => {
    if (a.sort_order === null) return 1;
    if (b.sort_order === null) return -1;
    return a.sort_order - b.sort_order;
  });

  const variablesFields = (variables: any, baseIndex: number) => {
    // Sort variables to be same order as flagVariables
    variables.sort((a: any, b: any) => {
      const aIndex = flagVariables.findIndex((el: any) => el.id === a.id);
      const bIndex = flagVariables.findIndex((el: any) => el.id === b.id);
      return aIndex - bIndex;
    });

    return variables?.map((variable: any, variIndex: number) => {
      const variableTarget = flagVariables.find(
        (el: any) => el.id === variable.id
      );

      const indexes = {
        variIndex,
        flagVariIndex: variIndex,
        flagVariId: variable.id,
        baseIndex,
      };

      if (baseIndex === 0 && !isRollout && !(isMab && meta.version >= 4)) {
        return <div key="-1"></div>;
      }

      if (
        variableTarget.var_key === "teaserTrackingPromotionCreative" ||
        variableTarget.var_key === "bannerTrackingPromotionCreative"
      ) {
        return null;
      }

      const customLabel = cxsCustomLabels[variableTarget.var_key]
        ? cxsCustomLabels[variableTarget.var_key]
        : variableTarget.var_key;

      if (variableTarget.type === "custom_csx_teaser") {
        return (
          <CustomCxsTeaser
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      // This gets overwritten in TrackingPromotionCreativeField.tsx
      if (
        (variableTarget.id === 181 &&
          variableTarget.var_key === "trackingPromotionCreative") ||
        (variableTarget.id === 262 &&
          variableTarget.var_key === "trackingPromotionCreative")
      ) {
        return null;
      }

      if (variableTarget.type === "custom_csx_ns_teaser") {
        return (
          <CustomCxsNsTeaser
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      if (variableTarget.type === "custom_csx_side_wide_banner") {
        return (
          <CustomCxsSiteWideBanner
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      if (variableTarget.var_key === "fullSiteWideBannerApps") {
        return (
          <CustomCxsSwbJson
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            label={customLabel}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      if (variableTarget.type === "custom_csx_banner") {
        return (
          <CustomCxsBanner
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      if (variableTarget.var_key === "excludeMemberArticle") {
        return (
          <>
            <Grid container key={"excludeMemberArticle-grid"}>
              <Grid item xs={12}>
                <Box mb={1} mt={1}>
                  <Typography variant="h6">Exclusions</Typography>
                  <Typography variant="body2">
                    An excluded article will have none of the discounts above
                    applied.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <BooleanField
              key={variableTarget.id}
              onChangeVariables={onChangeVariables}
              label={customLabel}
              indexes={indexes}
              variable={variable}
              disabled={editDisabled}
            />
          </>
        );
      }

      if (
        variableTarget.type === "boolean" ||
        variableTarget.type === "textColor"
      ) {
        return (
          <BooleanField
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            label={customLabel}
            indexes={indexes}
            variable={variable}
            disabled={editDisabled}
          />
        );
      }

      if (variableTarget.type === "datepicker") {
        return (
          <DatepickerField
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            label={customLabel}
            indexes={indexes}
            variable={variable}
            disabled={
              config.user.level === 25 || config.user.level === 100
                ? stateStatus > 30
                : editDisabled
            }
          />
        );
      }

      if (variableTarget.type === "json") {
        return (
          <JsonField
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            label={customLabel}
            indexes={indexes}
            variable={variable}
          />
        );
      }

      if (variableTarget.type === "aitext") {
        return (
          <AiText
            key={variableTarget.id}
            onChangeVariables={onChangeVariables}
            label={customLabel}
            indexes={indexes}
            variable={variable}
            ruleData={ruleData}
          />
        );
      }

      const textFieldContent = (
        <div key={variableTarget.var_key}>
          <TextField
            margin="dense"
            value={variable.value || ""}
            name={variableTarget.var_key}
            label={customLabel}
            type="text"
            fullWidth
            onChange={(e) =>
              onChangeVariables(e, {
                ...indexes,
              })
            }
            disabled={
              (config.user.level === 25 || config.user.level === 100) &&
              /countdownText|dealMessage/.test(variableTarget.var_key)
                ? stateStatus > 30
                : editDisabled
            }
          />
        </div>
      );

      if (variableTarget.var_key === "link") {
        return (
          <Grid container key={variableTarget.var_key + 10}>
            <Grid item xs={10}>
              {textFieldContent}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AndroidIosSupport />
            </Grid>
          </Grid>
        );
      }

      return textFieldContent;
    });
  };

  const VariationsButtonTooltip = () => {
    const btn = (
      <IconButton
        onClick={addNewVariations}
        size="large"
        disabled={editDisabled}
      >
        <AddBoxIcon fontSize="inherit" />
      </IconButton>
    );

    if (editDisabled) {
      return (
        <Tooltip
          title={config.cms_strings.experiments.add_variations_disabled_tooltip}
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                left: "-15px",
              },
            },
          }}
          arrow
          disableInteractive
        >
          <span>{btn}</span>
        </Tooltip>
      );
    } else {
      return btn;
    }
  };

  const includeInfoForCxs = flagVariables.find(
    (x: { var_key: string }) =>
      x.var_key === "expiresAt" || x.var_key === "excludeMemberArticle"
  );

  return (
    <Stack spacing={2}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h6">
            {isRollout ? "Variation" : "Variations"}
          </Typography>
        </Grid>
        <Grid item className={isRollout ? "hidden" : ""}>
          {VariationsButtonTooltip()}
        </Grid>
      </Grid>

      {newVariations?.map((variation: any, baseIndex: number) => {
        return (
          <Paper key={baseIndex} sx={{ p: 1 }}>
            <Accordion
              style={{ margin: "0px 0px 10px 0px", boxShadow: "none" }}
              defaultExpanded={baseIndex !== 0 && baseIndex === lastAdded}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={3} sx={{ padding: "0 0px" }}>
                  <Grid item xs={8}>
                    <Typography variant="caption">Variation name</Typography>
                    <Typography variant="h6">{variation.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" display="block">
                      Distribution
                    </Typography>
                    <Typography variant="caption">
                      {variation.traffic} %
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} sx={{ padding: "0 0px" }}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      value={variation.name}
                      name="name"
                      label="Variation name"
                      type="text"
                      fullWidth
                      onChange={(e) => onChangeVariation(e, baseIndex)}
                      disabled={
                        (!isRollout &&
                          !(isMab && meta && meta.version >= 4) &&
                          baseIndex === 0) ||
                        editDisabled
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      value={variation.traffic}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      type="text"
                      name="traffic"
                      label="Distribution"
                      helperText={
                        !isRollout
                          ? "Distribution must be between 5 and 95%"
                          : ""
                      }
                      fullWidth
                      onChange={(e) => onChangeVariation(e, baseIndex)}
                      disabled={editDisabled || isCreate || isMab || isRollout}
                      error={
                        isNaN(variation.traffic) ||
                        (!isRollout && variation.traffic < 5) ||
                        (!isRollout && variation.traffic > 95)
                      }
                    />
                  </Grid>
                </Grid>
                {(baseIndex > 0 || isMab) && includeInfoForCxs ? (
                  <Alert severity="info">
                    You can find more information and details about the{" "}
                    <Link
                      href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/Extensions.aspx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      fields in our sharepoint
                    </Link>
                    .
                  </Alert>
                ) : (
                  ""
                )}
                {variation.variables?.length > 0 &&
                (baseIndex > 0 || (isMab && meta && meta.version >= 4)) ? (
                  <Typography variant="subtitle2" sx={{ m: 1, ml: 0 }}>
                    Variables
                  </Typography>
                ) : (
                  ""
                )}
                {isRollout ||
                (isMab && meta && meta.version >= 4) ||
                baseIndex > 0
                  ? variablesFields(variation?.variables, baseIndex)
                  : ""}
                <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
                  <Grid item>
                    {baseIndex > 0 ? (
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={removeVariation}
                        data-index={baseIndex}
                        disabled={disableArchive || editDisabled}
                      >
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Paper>
        );
      })}
    </Stack>
  );
};

export default VariationsFields;
