import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ButtonBack from "../components/common/BackButton";
import { Stack, Box, Grid } from "@mui/material";

// import { Grid } from '@mui/material';

import { ApiResponse, FullFlag } from "../types/Types";
import { FetchFlag } from "../helpers/FetchData";

import EditFlag from "./EditFlag";
import { useConfig } from "./../context";

const FlagSettings: React.FC<{}> = () => {
  const { flagId } = useParams();
  const { config } = useConfig();

  // const [loading, setLoading] = useState(true);
  const [flagData, setFlagData] = useState<FullFlag>({
    name: "",
    description: "",
    flag_key: "",
    state: 0,
    variables: [],
    region_key: "",
    team_id: 0,
    archived: 0,
  });

  useEffect(() => {
    FetchFlag((output: ApiResponse) => {
      setFlagData(output.response[0]);
      // setLoading(false);
    }, Number(flagId));
  }, [flagId]);

  if (Object.keys(config).length === 0) {
    return <>Loading</>;
  }
  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Grid container>
          <Grid item>
            <ButtonBack link={"/features/" + flagId} text="Experiments" />
          </Grid>
        </Grid>
        <EditFlag
          flagId={Number(flagId)}
          flagData={flagData}
          submitDisabled={config.user.level < 50 || flagData?.archived === 1}
        />
      </Stack>
    </Box>
  );
};

export default FlagSettings;
