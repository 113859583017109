import React from "react";
import { Grid } from "@mui/material";
import CurrentLive from "./CurrentLive";
import RecentExperiments from "./RecentExperiments";
import Launched from "./Launched";
import LaunchedByTeam from "./LaunchedByTeam";
import LaunchedByBrand from "./LaunchedByBrand";
import LaunchedByRegion from "./LaunchedByRegion";
import LaunchedByPage from "./LaunchedByPage";
import LaunchedByTouchpoint from "./LaunchedByTouchpoint";
import ShareByTouchpoint from "./ShareByTouchpoint";
import Rollouts from "./Rollouts";
import { useTitle } from "../helpers/useTitle";

const ProgramReporting: React.FC<any> = (props: any) => {
  useTitle("Program Reporting");

  return (
    <Grid container spacing={2} justifyContent="flex-starts">
      <Grid item xs={12}>
        <CurrentLive />
      </Grid>
      <Grid item xs={12}>
        <Launched />
      </Grid>
      <Grid item xs={6}>
        <LaunchedByTeam />
      </Grid>
      <Grid item xs={6}>
        <LaunchedByRegion />
      </Grid>
      <Grid item xs={6}>
        <LaunchedByTouchpoint />
      </Grid>
      <Grid item xs={6}>
        <ShareByTouchpoint />
      </Grid>
      <Grid item xs={6}>
        <LaunchedByPage />
      </Grid>
      <Grid item xs={6}>
        <LaunchedByBrand />
      </Grid>
      <Grid item xs={12}>
        <RecentExperiments />
      </Grid>
      <Grid item xs={12}>
        <Rollouts />
      </Grid>
    </Grid>
  );
};

export default ProgramReporting;
