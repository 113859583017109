import React, { useEffect } from "react";
import { signOut } from "../../helpers/Helpers";
const SignOut: React.FC<{}> = () => {
  useEffect(() => {
    signOut();
  }, []);
  return null;
};

export default SignOut;
