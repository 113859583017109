import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  DialogContent,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SearchField from "../components/common/Searchfield";
import { useConfig } from "./../context";
import { FetchMetrics } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";

const GlobalMetrics: React.FC<any> = ({
  metricsListOpen,
  handleMetricsListClose,
  addCustomMetricWrapper,
  setRule,
  rule,
  maxNum,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [metricsList, setMetricsList] = useState([]);
  const [allMetrics, setAll] = useState([]);
  const { config } = useConfig();

  useEffect(() => {
    if (searchQuery.length === 0) {
      setMetricsList(allMetrics);
    } else {
      setMetricsList(
        allMetrics.filter(
          (i: any) =>
            i.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (i.description
              ? i.description.toLowerCase().includes(searchQuery.toLowerCase())
              : false)
        )
      );
    }
  }, [allMetrics, searchQuery]);

  useEffect(() => {
    FetchMetrics((output: ApiResponse) => {
      if (output?.status !== 200) {
        enqueueSnackbar(output.message, { variant: "error" });
      } else {
        setAll(output.response);
      }
    });
  }, []);

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length === 0) {
      setMetricsList(allMetrics);
    } else {
      setMetricsList(
        allMetrics.filter(
          (i: any) =>
            i.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            i.description.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const closeGlobalMetrics = () => {
    setAll((prevstate: any) => {
      return prevstate.map((item: any) => {
        return { ...item, added: false };
      });
    });
    handleMetricsListClose();
  };

  const tagMetric = (item: any, id: any) => {
    const numAdded = allMetrics.filter((i: any) => i.added).length;
    const metricAdded =
      allMetrics.filter((item: any) => item.id === id && item.added).length > 0;
    if (numAdded + rule.metrics.length < maxNum || metricAdded === true) {
      setAll((prevState: any) => {
        return prevState.map((item: any) => {
          if (item.id === id) {
            return { ...item, added: !!!metricAdded };
          } else {
            return item;
          }
        });
      });
    } else {
      enqueueSnackbar("Max number of metrics added", {
        variant: "warning",
      });
    }
  };

  const orderMetrics = (metrics: []) => {
    return metrics.map((m: any, index) => {
      return { ...m, index: index };
    });
  };

  const addMetrics = () => {
    setRule((prevState: any) => {
      const metrics = prevState.metrics || [];
      const addMetrics: any = allMetrics.filter((i: any) => i.added);
      for (let i = 0; i < addMetrics.length; i++) {
        const newMetric = {
          id: addMetrics[i].id,
          name: addMetrics[i].name,
          type: addMetrics[i].type,
          event: addMetrics[i].event,
          filter: addMetrics[i].filter,
          pointer: addMetrics[i].pointer,
          currency: addMetrics[i].currency,
          direction: addMetrics[i].direction,
          is_success: rule.type === "mab" ? true : false,
          custom_filter: addMetrics[i].custom_filter,
          department_filter: addMetrics[i].department_filter,
          winning_direction: addMetrics[i].winning_direction,
          department_filter_disabled: addMetrics[i].department_filter_disabled,
        };

        metrics.push(newMetric);
      }
      const ordered: any = orderMetrics(metrics);
      const data = {
        ...prevState,
        metrics: ordered,
      };

      return data;
    });
    closeGlobalMetrics();
  };
  return (
    <Dialog
      open={metricsListOpen}
      onClose={closeGlobalMetrics}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <Grid container alignItems={"center"}>
          <Grid item xs={2}>
            <Typography variant="h6">Metrics</Typography>
          </Grid>
          <Grid item xs sx={{ "& .MuiFormControl-root": { width: "90%" } }}>
            <SearchField
              value={searchQuery}
              handleSearchChange={handleSearch}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: config.darkMode
            ? "transparent"
            : "#f3f3f3!important",
          minHeight: "70vh",
          maxHeight: "70vh",
          pt: "10px!important",
        }}
      >
        <Stack spacing={1}>
          {metricsList.map((row: any, key: any) => {
            return (
              <Paper
                key={key}
                sx={{ p: 1, cursor: "pointer", "&:hover": { opacity: "0.6" } }}
                onClick={(e: any) => {
                  tagMetric(e, row.id);
                }}
                className="metric-row"
              >
                <Grid container alignItems="center">
                  <Grid item xs={11} container>
                    <Grid item xs={12}>
                      <Typography variant="button">{row.name}</Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">
                        {row.description}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}>
                    {row.added ? (
                      <CheckIcon />
                    ) : (
                      <AddIcon
                        sx={{
                          opacity:
                            rule.metrics.length +
                              allMetrics.filter((item: any) => item.added)
                                .length <
                            10
                              ? "1.0"
                              : "0.4",
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            {config.user.level >= 50 ? (
              <Button onClick={addCustomMetricWrapper} variant="outlined">
                Add custom metric
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <Button onClick={closeGlobalMetrics} variant="outlined">
              Close
            </Button>
            <Button
              onClick={addMetrics}
              variant="contained"
              sx={{ ml: 1 }}
              disabled={allMetrics.filter((i: any) => i.added).length < 1}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalMetrics;
