const teaserFields = [
  {
    label: "Background color",
    options: {
      choices: [
        { value: "red", label: "Red" },
        {
          value: "sale-red",
          label: "Sale red",
        },
        {
          value: "lb-white",
          label: "LB white",
        },
        {
          value: "lighter-grey",
          label: "Lighter grey",
        },
        {
          value: "pink",
          label: "Pink",
        },
        {
          value: "member-pink",
          label: "Member pink",
        },
        {
          value: "l-blue",
          label: "L blue",
        },
        {
          value: "d-blue",
          label: "D blue",
        },
        {
          value: "white",
          label: "White",
        },
        {
          value: "black",
          label: "Black",
        },
        {
          value: "sand-1",
          label: "Sand-1",
        },
        {
          value: "stone-1",
          label: "Stone-1",
        },
        {
          value: "stone-2",
          label: "Stone-2",
        },
        {
          value: "stone-3",
          label: "Stone-3",
        },
        {
          value: "dawn-1",
          label: "Dawn-1",
        },
        {
          value: "dawn-2",
          label: "Dawn-2",
        },
        {
          value: "member-color",
          label: "Member color",
        },
        {
          value: "plus-color",
          label: "Plus color",
        },
        {
          value: "conscious-green",
          label: "Conscious green",
        },
      ],
    },
    field_type: "dropdown",
    name: "backgroundColor",
    value: "",
  },
  {
    label: "Background image",
    options: null,
    field_type: "multi_text",
    name: "src",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "CTA style",
    options: {
      choices: [
        { value: "cta button|white", label: "cta button|white" },
        { value: "cta button|black", label: "cta button|black" },
        { value: "cta link|white", label: "cta link|white" },
        { value: "cta link|black", label: "cta link|black" },
      ],
    },
    field_type: "dropdown",
    name: "ctastyle",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Headline",
    options: null,
    field_type: "multi_text",
    name: "headline",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Text Color",
    options: {
      choices: [
        { value: "white", label: "White" },
        { value: "black", label: "Black" },
        { value: "sale", label: "Sale" },
        { value: "conscious-green", label: "Conscious green" },
      ],
    },
    field_type: "dropdown",
    name: "textColor",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Text alignment",
    options: {
      choices: [
        { value: "center", label: "Center" },
        { value: "left", label: "Left" },
        { value: "right", label: "Right" },
      ],
    },
    field_type: "dropdown",
    name: "textAlignment",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Text placement",
    options: {
      choices: [
        { value: "left top", label: "left top" },
        { value: "center top", label: "center top" },
        { value: "right top", label: "right top" },
        { value: "left middle", label: "left middle" },
        { value: "center middle", label: "center middle" },
        { value: "right middle", label: "right middle" },
        { value: "left bottom", label: "left bottom" },
        { value: "center bottom", label: "center bottom" },
        { value: "right bottom", label: "right bottom" },
      ],
    },
    field_type: "dropdown",
    name: "textPlacement",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Preamble bottom",
    options: null,
    field_type: "multi_text",
    name: "preambleBottom",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Text 1",
    options: null,
    field_type: "multi_text",
    name: "textone",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Vignette",
    options: null,
    field_type: "multi_text",
    name: "vignette",
    value: "",
    belongsTo: "ratios",
  },
  {
    label: "Text size",
    options: {
      choices: [
        { value: "default-s", label: "default-s" },
        { value: "default-m", label: "default-m" },
        { value: "default-l", label: "default-l" },
        { value: "default-xl", label: "default-xl" },
      ],
    },
    field_type: "dropdown",
    name: "textSize",
    value: "",
  },
  {
    label: "CTA",
    options: null,
    field_type: "cta",
    name: "cta",
    value: [{ url: "link", text: "cta text" }],
  },
  {
    label: "Show background color",
    options: {
      choices: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],
    },
    field_type: "dropdown",
    name: "showBackgroundColor",
    value: "",
  },
  {
    label: "Hide CTAs",
    options: {
      choices: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],
    },
    field_type: "dropdown",
    name: "hideCTAs",
    value: "",
  },
];

export default teaserFields;
