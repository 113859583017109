import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByTouchpoint } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { Card, CardContent, Typography } from "@mui/material";
import CenteredSpinner from "../components/common/CenteredSpinner";

const ShareByTouchpoint: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { config } = useConfig();
  const [tpsInChart, setTpsInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  useEffect(() => {
    if (config.touchpoints.length > 0 && isLoaded === false) {
      FetchStatsLaunchedByTouchpoint((output: any) => {
        setIsLoaded(true);

        const allTps: any = config.touchpoints.map((tp: any) => {
          return { name: tp.name, value: 0 };
        });
        const all: any = output.response;
        all.forEach((item: any) => {
          var touchpoint = allTps.find(function (touch: any) {
            return touch.name === item.touchpoint.toLowerCase();
          });

          if (touchpoint) {
            touchpoint.value += item.experiments_started;
          }
        });

        setTpsInChart(
          allTps
            .map((i: any) => (i.value > 0 ? i : null))
            .filter((i: any) => i !== null)
        );

        const palette = ColorPalette(allTps.length);
        const colors: any = [];
        allTps.forEach((r: any, idx: number) => {
          colors[idx] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config, isLoaded]);

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color, textTransform: "capitalize" }}>{value}</span>;
  };
  const calcShare = (value: number) => {
    const total = tpsInChart.reduce((acc: number, tp: any) => {
      return acc + tp.value;
    }, 0);
    return ((value / total) * 100).toFixed(1) + "%";
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: any) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value, fill, name } =
      props;
    const radius = innerRadius + (outerRadius - innerRadius) * 2.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const capName = name.charAt(0).toUpperCase() + name.slice(1);
    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${capName}: ${calcShare(value)}`}
      </text>
    );
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Share by Touchpoint</Typography>
        {!isLoaded ? (
          <CenteredSpinner />
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="value"
                data={tpsInChart}
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                label={renderCustomizedLabel}
              >
                {tpsInChart.map((entry: any, index: number) => (
                  <Cell key={`cell-${entry.name}`} fill={chartColors[index]} />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{ fontSize: 12, right: -10 }}
                formatter={legendFormatter}
                layout="vertical"
                align="right"
                verticalAlign="top"
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default ShareByTouchpoint;
