import React from "react";
import moment from "moment";

const YTicker: React.FC<any> = ({ x, y, stroke, payload }) => {
  const year = moment(payload.value).format("YYYY");
  const month = moment(payload.value).format("MMM");

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={12} fill="#666" fontSize="12">
        <tspan textAnchor="middle" x="0">
          {month}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="15">
          {year}
        </tspan>
      </text>
    </g>
  );
};

export default YTicker;
