import { Stack } from "@mui/material";

import { MetricItem } from "./MetricItem";

export const MetricsTable = (props: any) => {
  return (
    <Stack direction="column" spacing={3} sx={{ marginTop: "50px" }}>
      {props.experimentData.metrics.map((metric: any, index: number) => {
        return (
          <MetricItem
            bucketedVisitors={props.bucketedVisitors}
            key={metric.index}
            metric={metric}
            experimentId={props.experimentData.id}
            index={index}
            winning_direction={metric.winning_direction}
            variationData={props.experimentData.variations}
            getVariationName={props.getVariationName}
            getVariationColor={props.getVariationColor}
            variationsMeta={props.experimentData.variations}
            disableGraph={props.disableGraph}
            mockEnabled={props.mockEnabled}
            isSegmented={props.isSegmented}
          />
        );
      })}
    </Stack>
  );
};
