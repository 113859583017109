import React from "react";

import { Box, CircularProgress } from "@mui/material";

interface CenteredSpinnerProps {
  size?: number | string;
  sx?: any;
}

const CenteredSpinner: React.FC<CenteredSpinnerProps> = ({
  size,
  sx,
}: CenteredSpinnerProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="inherit" size={size} sx={sx} />
    </Box>
  );
};

export default CenteredSpinner;
