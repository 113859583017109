import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Grid,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useConfig } from "./../context";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import { FetchAudiences } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";
import SearchField from "../components/common/Searchfield";

const GlobalAudiences: React.FC<any> = ({
  addAudience,
  addCustomAudience,
  addDisabled,
  checkAllowedLocales,
  brandId,
}) => {
  const { config, setConfig } = useConfig();
  const [audiences, setAudiences] = useState([]);
  const [allAudiences, setAllAudiences] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleBackdrop = (b: boolean) => {
    setConfig((prevState: any) => {
      return {
        ...prevState,
        showBackdrop: b,
      };
    });
  };
  const getAudiences = () => {
    setSearchQuery("");
    if (allAudiences.length < 1) {
      toggleBackdrop(true);
      FetchAudiences((output: ApiResponse) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
          toggleBackdrop(false);
        } else {
          const sortedAudiences: any = [...output.response]
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .filter((i: any) => i.brand_id === brandId);
          setAudiences(sortedAudiences);
          setAllAudiences(sortedAudiences);
          setOpen(true);
          toggleBackdrop(false);
        }
      });
    } else {
      setOpen(true);
      setAudiences(allAudiences);
    }
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length === 0) {
      setAudiences(allAudiences);
    } else {
      setAudiences(
        allAudiences.filter(
          (i: any) =>
            i.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            i.description.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const addCustomAudienceWrapper = () => {
    setOpen(false);
    addCustomAudience();
  };
  const addAudienceWrapper = (e: any) => {
    setOpen(false);
    addAudience(e);
  };

  const getAudienceJson = (a: any) => {
    const data = {
      name: a.name,
      attribute: a.attribute,
      matchType: a.matchType,
      values: a.values.includes("|") ? a.values.split("|") : [a.values],
    };
    return JSON.stringify(data);
  };
  const audienceRow = (row: any, key: any) => {
    return checkAllowedLocales(row.values) || row.attribute !== "locale" ? (
      <Paper
        key={key}
        sx={{ p: 1, cursor: "pointer", "&:hover": { opacity: "0.6" } }}
        onClick={addAudienceWrapper}
        data-audience={getAudienceJson(row)}
      >
        <Grid container alignItems="center">
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Typography variant="button">{row.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{row.description}</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    ) : (
      false
    );
  };

  return (
    <>
      <IconButton onClick={getAudiences} size="large" disabled={addDisabled}>
        <AddBoxIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container alignItems={"center"}>
            <Grid item xs={2}>
              <Typography variant="h6">Audiences</Typography>
            </Grid>
            <Grid item xs sx={{ "& .MuiFormControl-root": { width: "90%" } }}>
              <SearchField
                value={searchQuery}
                handleSearchChange={handleSearch}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={addCustomAudienceWrapper} variant="outlined">
                New audience
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: config.darkMode
              ? "transparent"
              : "#f3f3f3!important",
            minHeight: "70vh",
            maxHeight: "70vh",
            pt: "10px!important",
          }}
        >
          <Stack spacing={1}>
            {audiences.map((row: any, key: any) => {
              return audienceRow(row, key);
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GlobalAudiences;
