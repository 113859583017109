import { Configuration } from "@azure/msal-browser";
import { getRedirectUri } from "./Helpers";

export const msalConfig: Configuration = {
  auth: {
    clientId: "561447dd-47df-45c9-b388-dfad675949e1",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    redirectUri: getRedirectUri(),
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (process.env.NODE_ENV !== "production") {
          console.warn(message);
        }
      },
      piiLoggingEnabled: false,
    },
    tokenRenewalOffsetSeconds: 1800 // Refresh token if less than 30 minutes to expiration
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
};
