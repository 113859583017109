import React from "react";
import { useNavigate } from "react-router-dom";
import { ArchiveFlag } from "../helpers/FetchData";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import UndoIcon from "@mui/icons-material/Undo";

const UnArchiveFlag: React.FC<any> = ({ flagId }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const submitArchive = () => {
    confirm({
      title: "Restore feature",
      description: "Are you sure you want to restore the feature?",
      confirmationButtonProps: { color: "error", variant: "contained" },
    })
      .then(() => {
        ArchiveFlag(
          (output: any) => {
            if (output?.status !== 200) {
              enqueueSnackbar(output.message, { variant: "error" });
            } else {
              enqueueSnackbar("Feature restore", { variant: "success" });
              navigate(`/features`);
            }
          },
          Number(flagId),
          { archive: "false" }
        );
      })
      .catch(() => {
        console.log("Cancelled");
      });
  };

  return (
    <div style={{ float: "right" }}>
      <Button
        color="error"
        variant="outlined"
        onClick={submitArchive}
        startIcon={<UndoIcon />}
      >
        Restore
      </Button>
    </div>
  );
};

export default UnArchiveFlag;
