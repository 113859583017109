const bannerFields = [
  {
    label: "Background image",
    options: null,
    field_type: "multi_text",
    name: "backgroundImagePath",
    value: "",
  },
  {
    label: "Background color",
    options: {
      choices: [
        { value: "red", label: "Red" },
        {
          value: "sale-red",
          label: "Sale red",
        },
        {
          value: "lb-white",
          label: "LB white",
        },
        {
          value: "lighter-grey",
          label: "Lighter grey",
        },
        {
          value: "pink",
          label: "Pink",
        },
        {
          value: "member-pink",
          label: "Member pink",
        },
        {
          value: "l-blue",
          label: "L blue",
        },
        {
          value: "d-blue",
          label: "D blue",
        },
        {
          value: "white",
          label: "White",
        },
        {
          value: "black",
          label: "Black",
        },
        {
          value: "sand-1",
          label: "Sand-1",
        },
        {
          value: "stone-1",
          label: "Stone-1",
        },
        {
          value: "stone-2",
          label: "Stone-2",
        },
        {
          value: "stone-3",
          label: "Stone-3",
        },
        {
          value: "dawn-1",
          label: "Dawn-1",
        },
        {
          value: "dawn-2",
          label: "Dawn-2",
        },
        {
          value: "member-color",
          label: "Member color",
        },
        {
          value: "plus-color",
          label: "Plus color",
        },
        {
          value: "conscious-green",
          label: "Conscious green",
        },
      ],
    },
    field_type: "dropdown",
    name: "backgroundColor",
    value: "",
  },
  {
    label: "Banner type",
    options: {
      choices: [
        { value: "noCTABanner", label: "noCTABanner" },
        { value: "bannerwithonecta", label: "bannerwithonecta" },
        { value: "bannerwithmultiplecta", label: "bannerwithmultiplecta" },
        { value: "informationlayer", label: "informationlayer" },
        { value: "editorialbanner", label: "editorialbanner" },
        { value: "saleBanner", label: "saleBanner" },
        { value: "bannerwithmodal", label: "bannerwithmodal" },
      ],
    },
    field_type: "dropdown",
    name: "bannerType",
    value: "",
  },
  {
    label: "Headline",
    options: null,
    field_type: "multi_text",
    name: "headline",
    value: "",
  },
  {
    label: "Headline color",
    options: {
      choices: [
        { value: "none", label: "none" },
        { value: "primary", label: "primary" },
        { value: "red", label: "red" },
        { value: "black", label: "black" },
        { value: "conscious-green", label: "conscious-green" },
        { value: "d-blue", label: "d-blue" },
        { value: "green", label: "green" },
        { value: "l-blue", label: "l-blue" },
        { value: "lb-white", label: "lb-white" },
        { value: "lighter-grey", label: "lighter-grey" },
        { value: "member-color", label: "member-color" },
        { value: "member-pink", label: "member-pink" },
        { value: "plus-color", label: "plus-color" },
        { value: "sale", label: "sale" },
        { value: "sale-red", label: "sale-red" },
        { value: "white", label: "white" },
        { value: "red-shade-orange", label: "red-shade-orange" },
        { value: "dark-grey", label: "dark-grey" },
        { value: "seasonal-1", label: "seasonal-1" },
        { value: "seasonal-2", label: "seasonal-2" },
        { value: "seasonal-3", label: "seasonal-3" },
        { value: "seasonal-4", label: "seasonal-4" },
        { value: "seasonal-5", label: "seasonal-5" },
      ],
    },
    field_type: "dropdown",
    name: "headlineColor",
    value: "",
  },
  {
    label: "CTA",
    options: null,
    field_type: "cta",
    name: "links",
    value: [{ path: "link", text: "cta text" }],
  },
  {
    label: "CTA style",
    options: {
      choices: [
        { value: "cta button", label: "cta button" },
        { value: "cta link", label: "cta link" },
      ],
    },
    field_type: "dropdown",
    name: "ctastyle",
    value: "",
    belongsTo: "links",
  },
  {
    label: "CTA color",
    options: {
      choices: [
        { value: "white", label: "white" },
        { value: "black", label: "black" },
      ],
    },
    field_type: "dropdown",
    name: "color",
    value: "",
    belongsTo: "links",
  },
  {
    label: "Modal text",
    options: null,
    field_type: "multi_text",
    name: "modalText",
    value: "",
  },
  {
    label: "Modal title",
    options: null,
    field_type: "multi_text",
    name: "modalTitle",
    value: "",
  },
  {
    label: "Modal headline",
    options: null,
    field_type: "multi_text",
    name: "modalHeadline",
    value: "",
  },
  {
    label: "Preamble",
    options: null,
    field_type: "multi_text",
    name: "preamble",
    value: "",
  },
  {
    label: "Preamble color",
    options: null,
    field_type: "multi_text",
    name: "preambleColor",
    value: "",
  },
];

export default bannerFields;

// const data = {
//   articleClass: {
//     bannerType: "expandable banner",
//     headlineSize: "l-text",
//     headlineFont: "default",
//   },
//   abTesting: false,
//   bleedOut: false,
//   enableViewTracking: true,
//   backgroundColor: "member-color",
//   backgroundImagePath:
//     "https://www2.hm.com/content/dam/regional-local-activities/seu/master-seu/2023-season-08/generalist/RSEU-blue%20gradient-3x2.png",
//   bannerType: "informationlayer", //"bannerwithmultiplecta"
//   headline: "Members earn 2x points per $1 for a limited time!",
//   headlineColor: "sale",
//   hideCta: false,
//   hideInWeb: false,
//   hidePreamble: false,
//   links: [
//     {
//       analyticsCategory: "CUSTOMERSERVICE_PRODUCTSANDQUALITY_RECALLEDITEMS",
//       color: "white",
//       coremetricsPageId: "Customerservice : Productsandquality : Recalleditems",
//       ctastyle: "cta button",
//       enableCTATracking: true,
//       path: "/en_us/customer-service/product-and-quality/recalled-items.html",
//       text: "Learn more",
//       title: "Recalled Items",
//     },
//   ],
//   modalText:
//     "Double points valid for H&M Loyalty Members through 03/22/2024- 03/23/2024. Cannot be used for Gift Card purchase. Valid in the US only.",
//   modalTitle: "Double Points",
//   preamble: "Not a member? Join now & get 10% off your first purchase!",
//   preambleColor: "black",
//   segmentId: "default",
//   trackingActivityCode: "Segment Default banner",
//   trackingActivityType: "MERCH",
//   trackingPromotionCreative: "Segmented Start Dept Default",
// };
