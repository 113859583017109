import React, { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useConfig } from "../../context";

const SiteMessage: React.FC<{}> = () => {
  const { config } = useConfig();

  const [messageHash, setMessageHash] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const storedHash = localStorage.getItem("seenFepSiteMessage") || "";
    setMessageHash(btoa(config.siteMessage));
    setOpen(config.siteMessage?.length > 0 && messageHash !== storedHash);
  }, [config.siteMessage, messageHash]);

  const handleClose = () => {
    localStorage.setItem("seenFepSiteMessage", messageHash);
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{
        boxShadow: open
          ? "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
          : "",
      }}
    >
      <Alert severity="warning" onClose={handleClose}>
        {config.siteMessage}
      </Alert>
    </Snackbar>
  );
};

export default SiteMessage;
