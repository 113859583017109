import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useConfig } from "./../context";
import { FetchGeneratedCopy } from "./../helpers/FetchData";
import CenteredSpinner from "./../components/common/CenteredSpinner";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";

const GenerateContent: React.FC<any> = ({ input, output }) => {
  const { config, setConfig } = useConfig();
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [generatedContent, setGeneratedContent] = useState({
    input: "",
    output: [],
  });
  (window as any).config = config;

  const cachedContent = config.generatedContent;

  const getContent = (refresh: boolean) => {
    if (input.length > 2) {
      setOpen(true);

      if (cachedContent.input !== input || refresh) {
        setGeneratedContent({ input: input, output: [] });

        FetchGeneratedCopy((output: any) => {
          if (output?.status !== 200) {
            enqueueSnackbar(output.message, { variant: "error" });
          } else {
            setGeneratedContent(output.response);
            setConfig((prevState: any) => {
              return {
                ...prevState,
                generatedContent: output.response,
              };
            });
          }
        }, input);
      } else {
        setGeneratedContent(cachedContent);
      }
    } else {
      setErrorOpen(true);
    }
  };

  const handleClick = (i: string) => {
    output(i);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => getContent(false)}>
        <AutoFixHighIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h6">Suggestions:</Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                onClick={() => getContent(true)}
                disabled={generatedContent.output.length === 0}
              >
                <CachedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: config.darkMode
              ? "transparent"
              : "#f3f3f3!important",
            minHeight: "70vh",
            maxHeight: "70vh",
            pt: "10px!important",
          }}
        >
          {generatedContent.output.length < 1 ? (
            <Stack direction="column" justifyContent="center" minHeight="65vh">
              <CenteredSpinner size={40} />
            </Stack>
          ) : (
            <Stack spacing={1}>
              {generatedContent.output.map((i: any, key: any) => {
                return (
                  <Paper
                    key={key}
                    sx={{
                      p: 2,
                      cursor: "pointer",
                      "&:hover": { opacity: "0.6" },
                    }}
                    onClick={() => handleClick(i)}
                  >
                    <Typography>{i}</Typography>
                  </Paper>
                );
              })}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={errorOpen} onClose={() => setErrorOpen(false)}>
        <DialogContent sx={{ p: "30px" }}>
          <IconButton
            onClick={() => setErrorOpen(false)}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body2">
            Your first variation must have data to use this functionality.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GenerateContent;
