import React, { useState } from "react";
import { Grid, InputLabel } from "@mui/material";

// @ts-ignore
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor/dist/jsoneditor.css";

const CustomCxsBanner: React.FC<any> = ({
  onChangeVariables,
  indexes,
  label,
  variable,
}) => {
  let parsedVariable = {};
  if (variable.value.length !== 0) parsedVariable = JSON.parse(variable.value);
  const [jsonData, setJsonData] = useState(parsedVariable);

  const jsonHandler = (value: any): void => {
    const string = JSON.stringify(value);
    setJsonData(value);

    const fakeEvent = { target: { value: string, type: null } };
    onChangeVariables(fakeEvent, indexes);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel>{label}</InputLabel>
        <JsonEditor
          value={jsonData}
          onChange={jsonHandler}
          mode="code"
          htmlElementProps={{ style: { height: 400 } }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomCxsBanner;
