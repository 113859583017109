import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import sendEvent from "./sendEvent";

const PageView: React.FC<any> = () => {
  let location = useLocation();

  useEffect(() => {
    sendEvent("page_view ", "page", [
      { name: "page", stringValue: location.pathname },
    ]);
  }, [location]);

  return <></>;
};

export default PageView;
