import React from "react";
import { Typography, Box, Grid, Tooltip } from "@mui/material";
import QRCode from "react-qr-code";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { isTargetingApps } from "../helpers/Helpers";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "./../context";

const generateQRCode = (
  variation: any,
  state: number,
  id: number,
  env: string
) => {
  const scheme =
    env === "prod" ? "com.hm.goe://" : "com.accenture.HMMobileApp://";
  const params = new URLSearchParams();
  params.append("v", "1");
  params.append("exp", id.toString());
  params.append("var", variation.toString());
  params.append("state", state === 30 ? "prod" : state === 20 ? "qa" : "dev");
  const url = new URL(scheme + "fabulous.applink.hm.com?" + params.toString());
  return url.toString();
};
const ExperimentMeta: React.FC<any> = ({ ruleData }) => {
  const { config } = useConfig();

  return ruleData.id > 0 ? (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={12} md={3} sx={{}}>
        <Typography variant="h6">Experiment meta:</Typography>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Experiment-ID:</Typography>
          <Typography variant="subtitle2">{ruleData.id}</Typography>
        </Box>

        {ruleData.variations.map((e: any, index: number) => (
          <Box key={index} display="flex" justifyContent="space-between">
            <Typography variant="body2">{e.name}:</Typography>
            <Typography variant="subtitle2">{e.id}</Typography>
          </Box>
        ))}
      </Grid>
      {isTargetingApps(ruleData) &&
      ruleData.state <= 30 &&
      ruleData.state >= 10 ? (
        <Grid item xs={12} md={9} container>
          <Grid item xs={12} container>
            <Grid item>
              <Typography variant="h6">App links:</Typography>
            </Grid>
            <Grid item>
              <HelpToolTip
                text={config.cms_strings.experiments.qr_codes_tooltip}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Production app:</Typography>
            </Grid>
            {ruleData.variations.map((e: any, index: number) => (
              <Grid item xs={2} key={index}>
                <Tooltip
                  title={
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: 200, width: 200 }}
                      value={generateQRCode(
                        e.id,
                        ruleData.state,
                        ruleData.id,
                        "prod"
                      )}
                      viewBox={`0 0 256 256`}
                    />
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        border: "1px solid black",
                        bgcolor: "white",
                        "& .MuiTooltip-arrow": {
                          color: "black",
                        },
                      },
                    },
                  }}
                >
                  <QrCode2Icon sx={{ fontSize: 50 }} />
                </Tooltip>
                <Typography variant="body2">{e.name}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Test app:</Typography>
            </Grid>

            {ruleData.variations.map((e: any, index: number) => (
              <Grid item xs={2} key={index}>
                <Tooltip
                  title={
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: 200, width: 200 }}
                      value={generateQRCode(
                        e.id,
                        ruleData.state,
                        ruleData.id,
                        "test"
                      )}
                      viewBox={`0 0 256 256`}
                    />
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        border: "1px solid black",
                        bgcolor: "white",
                        "& .MuiTooltip-arrow": {
                          color: "black",
                        },
                      },
                    },
                  }}
                >
                  <QrCode2Icon sx={{ fontSize: 50 }} />
                </Tooltip>
                <Typography variant="body2">{e.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  ) : null;
};

export default ExperimentMeta;
