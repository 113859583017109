const expireTime = 1800000;

const updateSidExpiration = () => {
  const expiration = Number(Date.now()) + expireTime;
  localStorage.setItem("fepSidExpires", String(expiration));
};

const newSid = () => {
  const expiration = Number(Date.now()) + expireTime;
  const id = Math.random().toFixed(16).substring(2);
  const sid = id + "." + expiration;
  localStorage.setItem("fepSid", sid);
  localStorage.setItem("fepSidExpires", String(expiration));
  return sid;
};

const getSid = () => {
  const storedSid = localStorage.getItem("fepSid");
  const sidExpired = localStorage.getItem("fepSidExpires")
    ? Number(localStorage.getItem("fepSidExpires")) < Date.now()
    : true;
  if (!storedSid || sidExpired) {
    return newSid();
  } else {
    updateSidExpiration();
    return storedSid;
  }
};

export default getSid;
