import React from "react";

import { Link, TextField, Typography } from "@mui/material";

const TrackingPromotionCreativeField: React.FC<any> = ({
  ruleData,
  setRuleData,
  flagVariables,
  editDisabled,
}) => {
  const onChangeVariables = (e: any) => {
    const { value, name } = e.target;
    const variations = ruleData.variations.map(
      (variation: any, variationKey: number) => {
        variationKey !== 0 &&
          variation.variables.forEach((variable: any) => {
            if (variable.id === feature.id) variable.value = value;
            
            // trackingPromotionCreative in TST for northstar_teaser_component feature
            if (variable.id === 181) variable.value = value; 
            // trackingPromotionCreative in Production for northstar_teaser_component feature
            if (variable.id === 262) variable.value = value; 
          });
        return variation;
      }
    );

    setRuleData((prevState: any) => ({ ...prevState, variations: variations }));

    const trackingPromotionCreative = {
      name: "Tracking promotion creative",
      values: value.split('|'),
      attribute: name,
      matchType: "eq",
      index: -1,
    };
    let newAttributes: any;

    const exist = ruleData.attributes.find((e: any) => e.attribute === name);
    if (exist) {
      newAttributes = ruleData.attributes.map((e: any) =>
        e.attribute === name ? trackingPromotionCreative : e
      );
    } else {
      newAttributes = [...ruleData.attributes, trackingPromotionCreative];
    }

    setRuleData((prevState: any) => ({
      ...prevState,
      attributes: newAttributes,
    }));
  };

  const feature = flagVariables.find(
    (obj: any) =>
      obj.var_key === "teaserTrackingPromotionCreative" ||
      obj.var_key === "bannerTrackingPromotionCreative"
  );

  if (!feature) return null;

  const teaserOrBanner =
    feature.var_key === "teaserTrackingPromotionCreative" ? "Teaser" : "Banner";

  const foundObject = ruleData.variations[1].variables.find(
    (obj: any) => obj.id === feature.id
  );

  return (
    <>
      <Typography variant="h6">
        {teaserOrBanner} targeting{" "}
        <Typography variant="caption">
          - What is this?{" "}
          <Link
            href="https://hennesandmauritz.sharepoint.com/:u:/r/sites/ExperimentationWebVisualAnalytics/SitePages/How-to-AB-Test-on-Teasers.aspx?csf=1&web=1&e=fOtPGa"
            target="_blank"
            rel="noreferrer"
          >
            Sharepoint for more details.
          </Link>{" "}
        </Typography>
      </Typography>
      <TextField
        margin="dense"
        value={foundObject.value || ""}
        name={feature.var_key}
        label={"Tracking promotion creative"}
        type="text"
        fullWidth
        onChange={(e) => onChangeVariables(e)}
        style={{ backgroundColor: "#fff" }}
        disabled={editDisabled}
        // variant="filled"
      />
    </>
  );
};

export default TrackingPromotionCreativeField;
