import React from "react";
import {
  Typography,
  Paper,
  Grid,
  Container,
  Link,
  Button,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InfoIcon from "@mui/icons-material/Info";
import ContentSquareSegments from "./ContentSquareSegments";

const cs = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Contentsquare</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Contentsquare is a powerful Visual analytics tool, enabling you to
          understand the WHY and the How in terms of user behaviour.
        </Typography>
        <br />
        <Typography>
          It's not only a fundamental source of data validated hypotheses, but
          it also enables you to delve in deeper when following up the in page
          behaviour using zone based heat mapping, journey analysis, page
          comparator, retroactive insights and session replays.
        </Typography>
        <br />
        <Box display="flex" alignItems={"center"}>
          <Typography>
            To find out more about Contentsquare visit our SharePoint{" "}
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              target="_blank"
              href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/Visual-Analytics.aspx"
            >
              Learn more
            </Button>
          </Typography>
        </Box>
        <br />
        <Box display="flex" alignItems={"center"}>
          <Typography>
            Login to Contentsquare using SSO{" "}
            <Button
              variant="outlined"
              target="_blank"
              href="https://app.contentsquare.com/#/site-overview?project=20224"
            >
              Login
            </Button>
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

const ee = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Effective Experiments</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Effective Experiments is a 3rd Party platform that helps us scale and
          manage the practice of Experimentation at H&M, essentially enabling a
          single unified framework starting at capturing and prioritising
          experiment ideas, to a scalable hypothesis framework and a query
          engine so you can learn from all past experiments.​
        </Typography>
        <br />
        <Box display="flex" alignItems={"center"}>
          <Typography>
            To find out more about Effective Experiments visit our SharePoint
            here
          </Typography>
          <Button
            sx={{ marginLeft: 2 }}
            variant="outlined"
            href="https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/pages/1224802420/Effective+Experiments+onboarding"
            target="_blank"
          >
            Learn more
          </Button>
        </Box>
        <br />
        <Typography>
          <strong>Important: </strong>All A/B tests must be captured in
          Effective Experiments{" "}
        </Typography>
        <br />
        <Box display="flex" alignItems={"center"}>
          <Typography>
            Login to Effective Experiments using SSO{" "}
            <Button
              variant="outlined"
              target="_blank"
              href="https://hm.effectiveexperiments.com/r/app/dashboard?project=all"
            >
              Login
            </Button>
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

const support = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Support</Typography>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid item container xs={6} spacing={3}>
          <Grid item xs={1}>
            <ForwardToInboxIcon />
          </Grid>
          <Grid item xs>
            <Typography sx={{ wordWrap: "normal" }}>
              If you have any questions related <strong>fABulous</strong> or{" "}
              <strong>Effective Experiments</strong>, you can reach us by email:{" "}
              <Link href="mailto:experimentation-support@hm.com">
                Experimentation-support@hm.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={1}>
            <ForwardToInboxIcon />
          </Grid>
          <Grid item xs>
            <Typography>
              If you have any questions related <strong>Contentsquare</strong>,
              you can reach us by email:{" "}
              <Link href="mailto:BT-WAandVAsupport@hm.com">
                BT-WAandVAsupport@hm.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={1}>
            <InfoIcon />
          </Grid>
          <Grid item xs>
            <Typography>
              To find out more about<strong>fABulous</strong> or{" "}
              <strong>Contentsquare</strong> you can find supporting materials
              and information on SharePoint
            </Typography>
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              target="_blank"
              href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/Visual-Analytics.aspx"
            >
              Learn more
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const getContent = (page: string | undefined) => {
  switch (page) {
    case "cs":
      return cs();
    case "ee":
      return ee();
    case "support":
      return support();
    case "cs-segments":
      return <ContentSquareSegments />;
    default:
      return <></>;
  }
};
const Pages: React.FC<{}> = () => {
  const { page } = useParams();

  return (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-around">
          <Grid item xs={10} container spacing={2}>
            {getContent(page)}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Pages;
