import React from "react";
import { useNavigate } from "react-router-dom";
import { ArchiveFlag } from "../helpers/FetchData";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@mui/icons-material/Delete";

const SetArchiveFlag: React.FC<any> = ({ flagId, disabled, hasLiveRules }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const submitArchive = () => {
    if (hasLiveRules > 0) {
      enqueueSnackbar(
        "You cannot delete a feature that has one or more experiments with the state Live. ",
        { variant: "error" }
      );
    } else {
      confirm({
        title: "Delete feature",
        description:
          "Are you sure you want to delete the feature? The experiments in this features will only be available under the Results section (if the experiment has ever been live).",
        confirmationButtonProps: { color: "error", variant: "contained" },
      })
        .then(() => {
          ArchiveFlag(
            (output: any) => {
              if (output?.status !== 200) {
                enqueueSnackbar(output.message, { variant: "error" });
              } else {
                enqueueSnackbar("Feature deleted", { variant: "success" });
                navigate(`/features`);
              }
            },
            Number(flagId),
            { archive: true }
          );
        })
        .catch(() => {
          console.log("Cancelled");
        });
    }
  };

  return (
    <div style={{ float: "right" }}>
      <Button
        color="error"
        variant="outlined"
        onClick={submitArchive}
        disabled={disabled}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
    </div>
  );
};

export default SetArchiveFlag;
