import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  IconButton,
  FormLabel,
  Chip,
  Stack,
  Divider,
  Link,
  Alert,
  Box,
  Collapse,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import nsTeaserFields from "./nsTeaserFields.js";

// @ts-ignore
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor/dist/jsoneditor.css";

const CustomCxsNsTeaser: React.FC<any> = ({
  onChangeVariables,
  indexes,
  variable,
}) => {
  const initialParsedVariable =
    variable.value.length !== 0 ? JSON.parse(variable.value) : "";
  const [nsTeaserObj, setNsTeaserObj] = useState<any>(initialParsedVariable);

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const [editorKey, setEditorKey] = useState(0);
  const isFirstUpdate = useRef(true);

  useEffect(() => {
    const string = JSON.stringify(nsTeaserObj);
    const fakeEvent = { target: { value: string, type: null } };
    onChangeVariables(fakeEvent, indexes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nsTeaserObj]);

  useEffect(() => {
    if (isFirstUpdate.current) {
      setEditorKey((prevKey) => prevKey + 1);
      isFirstUpdate.current = false;
    }
  }, [nsTeaserObj]);

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  const handler = (event: any) => {
    const { value, name } = event.target;
    setNsTeaserObj((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const nestedHandler = (event: any) => {
    const { value, name } = event.target;
    setNsTeaserObj((prevState: any) => {
      return {
        ...prevState,
        fourFiveRatio: { ...prevState.fourFiveRatio, [name]: value },
        nineSixteenRatio: { ...prevState.nineSixteenRatio, [name]: value },
        sixteenNineRatio: { ...prevState.sixteenNineRatio, [name]: value },
        twoThreeRatio: { ...prevState.twoThreeRatio, [name]: value },
      };
    });
  };

  // When you add a new field
  const handleSelectChange = (event: any) => {
    const { value } = event.target;

    const obj = nsTeaserFields.find((item: any) => item.name === value);

    if (value === "image" || value === "video") {
      setNsTeaserObj((prevState: any) => {
        return {
          ...prevState,
          fourFiveRatio: { ...prevState.fourFiveRatio, [value]: obj?.value },
          nineSixteenRatio: {
            ...prevState.nineSixteenRatio,
            [value]: obj?.value,
          },
          sixteenNineRatio: {
            ...prevState.sixteenNineRatio,
            [value]: obj?.value,
          },
          twoThreeRatio: { ...prevState.twoThreeRatio, [value]: obj?.value },
        };
      });
    } else {
      setNsTeaserObj((prevState: any) => {
        return { ...prevState, [value]: obj?.value };
      });
    }
  };

  const ControlPanel = () => {
    return (
      <div>
        <Alert severity="info">
          Please see our{" "}
          <Link
            href="https://hennesandmauritz.sharepoint.com/:u:/r/sites/ExperimentationWebVisualAnalytics/SitePages/How-to-AB-Test-on-Teasers.aspx?csf=1&web=1&e=fOtPGa"
            target="_blank"
            rel="noreferrer"
          >
            sharepoint for more details
          </Link>{" "}
          for what is prerequisite to do in AEM to be able to do AB-Tests on
          Teasers/Banners. Explains what "Tracking promotion creative" is and
          that "Used for AB testing" needs to be checked in AEM.
        </Alert>
        <p>Pick the items below that you want to change:</p>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {nsTeaserFields.map(
            (item: { name: string; label: string; field_type: string }) => {
              const findIfAlreadyInPlace = Object.keys(nsTeaserObj).find(
                (find: any) => {
                  if (find === "fourFiveRatio") {
                    return nsTeaserObj.fourFiveRatio.hasOwnProperty(item.name);
                  }
                  return find === item.name;
                }
              );
              return (
                <Chip
                  label={item.label}
                  key={item.name}
                  disabled={findIfAlreadyInPlace ? true : false}
                  onClick={(e) =>
                    handleSelectChange({ target: { value: item.name } })
                  }
                />
              );
            }
          )}
        </Stack>
        <Divider sx={{ mt: 2 }} />
        <br />
      </div>
    );
  };

  const DropdownField = ({ variObj, variIndex }: any) => {
    return (
      <Grid item xs={6} key="grid-dropdown-field">
        <FormControl
          sx={{ minWidth: 150 }}
          style={{ width: "calc(100% - 50px)" }}
          margin="dense"
        >
          <InputLabel id="dropdown-select-label">{variObj.label}</InputLabel>
          <Select
            labelId="dropdown-select-label"
            value={nsTeaserObj[variObj.name] || ""}
            name={variObj.name}
            onChange={handler}
            label={variObj.label}
          >
            {variObj.options.choices.map(
              (item: { value: string; label: string }) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        <IconButton
          aria-label="delete"
          onClick={(e) => removeField(variObj.name)}
          style={{ margin: "16px 0 0px 5px" }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const ctaHandler = (e: any, ctaIndex: number) => {
    const { value, name } = e.target;

    setNsTeaserObj((prevState: any) => {
      return {
        ...prevState,
        ctas: prevState.ctas.map((cta: any, ctaPix: number) => {
          if (ctaIndex !== ctaPix) return cta;
          return { ...cta, [name]: value };
        }),
      };
    });
  };

  const addCtaHandler = () => {
    const ctaTemplate = nsTeaserFields.find(
      (item: any) => item.name === "ctas"
    );

    setNsTeaserObj((prevState: any) => {
      return {
        ...prevState,
        ctas: [
          ...prevState.ctas,
          {
            ...(typeof ctaTemplate?.value[0] === "object"
              ? ctaTemplate?.value[0]
              : {}),
          },
        ],
      };
    });
  };

  const removeCta = (event: any, index: number) => {
    setNsTeaserObj((prevState: any) => {
      return {
        ...prevState,
        ctas: prevState.ctas.filter(
          (cta: any, ctaPix: number) => index !== ctaPix
        ),
      };
    });
  };

  const removeField = (name: string) => {
    setNsTeaserObj((prevState: any) => {
      const obj = { ...prevState };
      delete obj[name];
      return obj;
    });
  };

  const removeNestedField = (name: string) => {
    setNsTeaserObj((prevState: any) => {
      const newState = {
        ...prevState,
        fourFiveRatio: { ...prevState.fourFiveRatio },
        nineSixteenRatio: { ...prevState.nineSixteenRatio },
        sixteenNineRatio: { ...prevState.sixteenNineRatio },
        twoThreeRatio: { ...prevState.twoThreeRatio },
      };

      delete newState.fourFiveRatio[name];
      delete newState.nineSixteenRatio[name];
      delete newState.sixteenNineRatio[name];
      delete newState.twoThreeRatio[name];

      return newState;
    });
  };

  const CtaField = () => {
    if (!nsTeaserObj?.ctas?.length) {
      return null;
    }

    return (
      <Grid item xs={12} key="grid-cta-field">
        <Grid key="cta-field" container spacing={1} sx={{ marginBottom: 1 }}>
          {nsTeaserObj?.ctas?.map((cta: any, ctaIndex: number) => {
            return (
              <Grid item xs={6} key={"cta" + ctaIndex}>
                <FormLabel id="demo-radio-buttons-group-label">
                  CTA {ctaIndex + 1}:
                </FormLabel>
                <br />
                <TextField
                  key={"text" + ctaIndex}
                  label="Text"
                  name="text"
                  value={cta.text || ""}
                  sx={{ minWidth: "150px", marginRight: 1 }}
                  style={{ width: "calc(50% - 30px)" }}
                  margin="dense"
                  onChange={(e) => ctaHandler(e, ctaIndex)}
                />
                <TextField
                  key={"url" + ctaIndex}
                  label="Link"
                  name="url"
                  margin="dense"
                  value={cta.url || ""}
                  sx={{ minWidth: "150px" }}
                  style={{ width: "calc(50% - 30px)" }}
                  onChange={(e) => ctaHandler(e, ctaIndex)}
                />
                <IconButton
                  aria-label="delete"
                  name="ctas"
                  onClick={(e) => removeCta(e, ctaIndex)}
                  style={{ margin: "16px 0 0px 5px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={addCtaHandler}>
              Add CTA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const jsonHandler = (value: any): void => {
    setNsTeaserObj(value);
  };

  return (
    <>
      <ControlPanel />
      <Grid key="teaser-fields" container spacing={1} sx={{ marginBottom: 1 }}>
        {Object.keys(nsTeaserObj).map((variObj: any, variIndex: number) => {
          const objValue = nsTeaserObj[variObj];
          const objName = variObj;

          const finder = nsTeaserFields.find(
            (item: any) => item.name === objName
          );

          if (variObj === "fourFiveRatio") {
            return Object.keys(objValue).map((key: any) => {
              return (
                <Grid item xs={6} key={key + "div-nested"}>
                  <TextField
                    key={key}
                    label={key}
                    name={key}
                    value={nsTeaserObj.fourFiveRatio[key] || ""}
                    sx={{ minWidth: 150 }}
                    style={{ width: "calc(100% - 50px)" }}
                    margin="dense"
                    onChange={nestedHandler}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => removeNestedField(key)}
                    style={{ margin: "16px 0 0px 5px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              );
            });
          }

          // show nexted but only fourFiveRatio

          if (!finder || finder.name === "ctas") return null;
          switch (finder.field_type) {
            case "dropdown":
              return (
                <DropdownField
                  key={finder.name}
                  variObj={finder}
                  variIndex={finder}
                />
              );
            default:
              return (
                <Grid item xs={6} key={finder.name + "div"}>
                  <TextField
                    key={finder.name}
                    label={finder.label}
                    name={finder.name}
                    value={nsTeaserObj[finder.name] || ""}
                    sx={{ minWidth: 150 }}
                    style={{ width: "calc(100% - 50px)" }}
                    margin="dense"
                    onChange={handler}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => removeField(finder.name)}
                    style={{ margin: "16px 0 0px 5px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              );
          }
        })}
        {CtaField()}
      </Grid>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="text" onClick={toggleEditorVisibility}>
          {isEditorVisible
            ? "Hide advanced developer editor"
            : "Open advanced developer editor"}
        </Button>
      </Box>
      <Collapse in={isEditorVisible}>
        <Alert severity="warning">
          If you made updates above, they will not be reflected beneath. Save
          your changes before editing the JSON.
        </Alert>

        <JsonEditor
          key={editorKey}
          value={nsTeaserObj}
          onChange={jsonHandler}
          mode="code"
          htmlElementProps={{ style: { height: 400 } }}
        />
      </Collapse>
    </>
  );
};

export default CustomCxsNsTeaser;
