import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import {
  AdminFetchTeams,
  AdminUpdateTeam,
  AdminCreateTeam,
  AdminArchiveTeam,
} from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useConfirm } from "material-ui-confirm";

const Teams: React.FC<{}> = () => {
  const [teams, setTeams] = React.useState([]);
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState({
    name: "",
    team_key: "",
  });
  const confirm = useConfirm();

  (window as any).teams = teams;
  (window as any).selectedTeam = selectedTeam;
  useEffect(() => {
    AdminFetchTeams((output: any) => {
      setTeams(output.response);
    });
  }, []);

  const handleEdit = (team_key: string) => {
    const team = teams.find((team: any) => team.team_key === team_key);
    if (team) {
      setSelectedTeam(team);
      setEditOpen(true);
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSelectedTeam((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    setSaving(true);
    AdminUpdateTeam((output: any, error: any) => {
      if (output.status === 200) {
        setSaving(false);
        setEditOpen(false);
        setTeams([]);
        AdminFetchTeams((output: any) => {
          setTeams(output.response);
        });
      }
    }, selectedTeam);
  };
  const handleCreate = () => {
    setSaving(true);
    AdminCreateTeam((output: any, error: any) => {
      if (output.status === 200) {
        setSaving(false);
        setCreateOpen(false);
        setTeams([]);
        AdminFetchTeams((output: any) => {
          setTeams(output.response);
        });
      }
    }, selectedTeam);
  };

  const handleArchive = (team_key: string) => {
    const team: any = teams.find((team: any) => team.team_key === team_key);
    if (team) {
      confirm({
        title: "Archive team",
        description: "Are you sure you want to archive this team?",
        confirmationButtonProps: { color: "error", variant: "contained" },
      })
        .then(() => {
          AdminArchiveTeam((output: any) => {
            if (output.status === 200) {
              setTeams([]);
              AdminFetchTeams((output: any) => {
                setTeams(output.response);
              });
            }
          }, Number(team.id));
        })
        .catch(() => {
          console.log("Cancelled");
        });
    }
  };
  return (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-around">
          <Grid item xs={8}>
            <Typography variant="h5">Teams</Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => setCreateOpen(true)}
              disabled={saving}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12} container>
            {teams.length === 0 ? (
              <Grid item xs={12}>
                <CenteredSpinner />
              </Grid>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Key</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.map((team: any) => (
                      <TableRow key={team.id}>
                        <TableCell>{team.name}</TableCell>
                        <TableCell>{team.team_key}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(team.team_key)}>
                            <CreateIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleArchive(team.team_key)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={editOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setEditOpen(false)}
      >
        <DialogTitle>Edit team</DialogTitle>

        <DialogContent sx={{ pt: "20px!important" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Name"
                name="name"
                value={selectedTeam.name}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Key"
                name="team_key"
                value={selectedTeam.team_key}
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSave} disabled={saving}>
            Save
          </Button>{" "}
        </DialogActions>
      </Dialog>
      <Dialog
        open={createOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setCreateOpen(false)}
      >
        <DialogTitle>Create team</DialogTitle>

        <DialogContent sx={{ pt: "20px!important" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Name"
                name="name"
                value={selectedTeam.name}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Key"
                name="team_key"
                value={selectedTeam.team_key}
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCreate} disabled={saving}>
            Save
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Teams;
