import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Container,
  TextField,
  Alert,
  Divider,
} from "@mui/material";
import { FetchCmsAdmin, UpdateCmsKey } from "../helpers/FetchData";
import LoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import { useConfig } from "../context";
import Loader from "../components/common/Loader";

const CmsAdmin: React.FC<{}> = () => {
  const [data, setData] = React.useState<any>([]);
  const [siteMessage, setSiteMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(Date.now().toString());
  const { config } = useConfig();
  const [userInfo, setUserInfo] = useState({ name: "", role: "" } as {
    name: string;
    role: string;
  });

  useEffect(() => {
    FetchCmsAdmin((output: any) => {
      const siteMessageIndex = output.response.findIndex(
        (i: any) => i.cms_key === "site_message",
      );
      const siteMessage = output.response.splice(siteMessageIndex, 1);
      setData(output.response);
      setSiteMessage(siteMessage[0]);
    });
  }, [triggerUpdate]);

  useEffect(() => {
    if (config.user && config.user.email) {
      const email: string = config.user.email;
      const name: string = email.split("@")[0].replace(".", " ");
      const role: string =
        config.user.role[0].toUpperCase() +
        config.user.role.slice(1).toLowerCase().replace("_", " ");
      setUserInfo({ name: name, role: role });
    }
  }, [config]);

  const handleSave = (e: any) => {
    setLoading(true);
    e.preventDefault();
    const cmsId = parseInt(e.currentTarget.elements[0].name);
    const cmsValue = e.currentTarget.elements[0].value;
    const data = { cms_value: cmsValue };
    UpdateCmsKey(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar("Could not update cms key!", { variant: "error" });
        } else {
          enqueueSnackbar("Cms key updated!", { variant: "success" });
          setTriggerUpdate(Date.now().toString());
        }
        setLoading(false);
      },
      cmsId,
      data,
    );
  };

  const cmsRow = (item: any, index: number) => {
    return (
      <Grid item xs={12} key={item.id} container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {item.category}.{item.cms_key}
          </Typography>
        </Grid>
        <form onSubmit={handleSave} style={{ width: "100%", display: "flex" }}>
          <Grid item xs={10}>
            <TextField
              margin="none"
              defaultValue={item.cms_value}
              type="text"
              fullWidth
              size="small"
              inputProps={{ "data-id": item.id }}
              name={item.id.toString()}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={1} sx={{ pl: 1 }}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Grid>
        </form>
      </Grid>
    );
  };
  const cmsPage = () => {
    return (
      <Container>
        <Paper sx={{ p: 2 }}>
          <Grid container justifyContent="space-around" spacing={2}>
            <Grid item xs={10}>
              <Typography mb={1} variant="h5">
                Site Message
              </Typography>
              <Typography>
                This message will be displayed on the top of the page across the
                platform until the user dismisses it.
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              container
              sx={{ pt: "0!important", mt: "0!important" }}
            >
              {siteMessage ? cmsRow(siteMessage, 0) : <></>}
            </Grid>
            <Grid item xs={10}>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Grid>
            <Grid item xs={10}>
              <Typography mb={1} variant="h5">
                Cms keys
              </Typography>
              <Typography variant="body2"></Typography>
            </Grid>

            <Grid
              item
              xs={10}
              container
              sx={{ pt: "0!important", mt: "0!important" }}
            >
              {data.map((item: any, index: number) => cmsRow(item, index))}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  };

  return userInfo.role.length < 1 ? (
    <Loader />
  ) : userInfo.role === "Administrator" ? (
    cmsPage()
  ) : (
    <Alert severity="error">You don't have permission to view this page.</Alert>
  );
};

export default CmsAdmin;
