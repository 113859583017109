/*import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const RangePicker = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update: any) => {
        setDateRange(update);
      }}
    />
  );
};*/

import React from "react";
import Moment from "moment";
import { DateRangePicker, DateRange } from "mui-daterange-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { OutlinedInput, IconButton, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpToolTip from "./HelpToolTip";
import { useConfig } from "../../context";

type Props = { handleDateChange: any };

export const RangePicker: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({});
  const { config } = useConfig();

  const toggleDatePicker = () => setOpen(!open);

  const formatDateRage =
    dateRange.startDate !== dateRange.endDate
      ? `${Moment(dateRange.startDate).format("MMM D")} - ${Moment(
          dateRange.endDate,
        ).format("MMM D")}`
      : "";

  const handleDatePickerChange = (range: DateRange) => {
    setDateRange(range);
    props.handleDateChange(range);
    setOpen(!open);
  };
  const resetDateRange = (event: any) => {
    event.stopPropagation();
    setDateRange({});
    props.handleDateChange({});
  };
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <FormControl
            onClick={toggleDatePicker}
            sx={{ m: 1, width: "100%" }}
            variant="outlined"
            size="small"
            className={
              dateRange.startDate !== undefined ||
              dateRange.endDate !== undefined
                ? "active-filter"
                : ""
            }
          >
            <InputLabel htmlFor="outlined-adornment-password">Date</InputLabel>
            <OutlinedInput
              autoComplete="off"
              id="outlined-adornment-password"
              value={formatDateRage}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarMonthIcon className="calender-icon" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={
                      dateRange.startDate === undefined &&
                      dateRange.endDate === undefined
                    }
                    onClick={resetDateRange}
                    edge="end"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Date"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} sx={{ pl: 1 }}>
          <HelpToolTip text={config.cms_strings.results.datepicker_tooltip} />
        </Grid>
      </Grid>
      <DateRangePicker
        wrapperClassName="date-range-picker"
        open={open}
        toggle={toggleDatePicker}
        onChange={(range) => handleDatePickerChange(range)}
      />
    </>
  );
};
