import React from "react";
import { Box } from "@mui/material";

interface ImageProps {
  src: string;
}

const Image: React.FC<ImageProps> = ({ src }: ImageProps) => {
  return (
    <Box
      sx={{ width: "100%", hight: "auto", m: 1 }}
      component="img"
      src={src}
      alt={src}
    />
  );
};

export default Image;
