import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FetchStatsRollouts, FetchLocales } from "../helpers/FetchData";
import moment from "moment";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useConfig } from "../context";

const CurrentLive: React.FC<any> = ({ summarize }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(0);

  const [selectedLocale, setSelectedLocale] = useState("all");
  const [selectedTeam, setSelectedTeam] = useState("all");
  const [selectedTouchpoint, setSelectedTouchpoint] = useState("all");
  const [selectedPage, setSelectedPage] = useState("all");
  const [liveTableData, setLiveTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const touchpoints = ["desktop", "mobile web", "ios", "android"];
  const { config } = useConfig();
  const [allLocales, setAllLocales] = useState([]);
  const [activeLocales, setActiveLocales] = useState([]);

  useEffect(() => {
    FetchLocales((output: any) => {
      if (output?.status !== 200) {
        console.log(output.message);
      } else {
        setAllLocales(output.response);
      }
    });
  }, []);

  useEffect(() => {
    FetchStatsRollouts((output: any) => {
      setIsLoaded(true);
      setLiveData(output.response);
      setLiveTableData(output.response);
    });
  }, []);

  const handleOpen = () => {
    if (summarize === true) {
      navigate("/programreporting");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getTouchpoints = (item: any) => {
    const tp = item.attributes
      .filter((i: any) => i.attribute === "touchpoint")
      .flatMap((a: any) => a.values);
    return tp.join(", ").toLowerCase();
  };
  const getLocales = (item: any) => {
    const tp = item.attributes
      .filter((i: any) => i.attribute === "locale")
      .flatMap((a: any) => a.values);
    return tp.join(", ").toLowerCase();
  };
  const getBrandName = (brand_id: number) => {
    const brand = config?.brands?.find((brand: any) => brand.id === brand_id);
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  const handleBrandChange = (e: any) => {
    setSelectedLocale("all");
    setActiveLocales(
      e.target.value === 0
        ? []
        : allLocales.filter((locale: any) => locale.brand_id === e.target.value)
    );

    setSelectedBrand((prev: any) => {
      return e.target.value;
    });
  };

  useEffect(() => {
    let data = liveData;

    if (selectedBrand !== 0) {
      data = data.filter((i: any) => i.brand_id === selectedBrand);
    }

    if (selectedLocale !== "all") {
      data = data.filter((i: any) =>
        i.attributes.some(
          (a: any) =>
            a.attribute === "locale" && a.values.includes(selectedLocale)
        )
      );
    }

    if (selectedTeam !== "all") {
      data = data.filter((i: any) => i.team_name === selectedTeam);
    }

    if (selectedTouchpoint !== "all") {
      data = data.filter((i: any) =>
        i.attributes.some(
          (a: any) =>
            a.attribute === "touchpoint" &&
            a.values.includes(selectedTouchpoint)
        )
      );
    }

    if (selectedPage !== "all") {
      data = data.filter((i: any) => i.page === selectedPage);
    }

    setLiveTableData(data);
  }, [
    selectedBrand,
    selectedLocale,
    selectedTeam,
    selectedTouchpoint,
    selectedPage,
    liveData,
  ]);

  const liveExperimentsList = (limit: number) => {
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="rule_id">ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell sx={{ width: 150 }}>Started</TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Brand"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="brand-select-label">Brand</InputLabel>
                    <Select
                      labelId="brand-select-label"
                      label="Brand"
                      id="brand-select"
                      defaultValue={0}
                      size="small"
                      name="brand_id"
                      onChange={handleBrandChange}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key={0} value={0}>
                        All
                      </MenuItem>
                      {config.brands.map((i: any) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Team"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="team-select-label">Team</InputLabel>
                    <Select
                      labelId="team-select-label"
                      label="Team"
                      id="team-select"
                      defaultValue="all"
                      size="small"
                      name="team_name"
                      onChange={(e: any) => setSelectedTeam(e.target.value)}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {config.teams.map((i: any) => (
                        <MenuItem key={i.id} value={i.name}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 130 }}>
                {summarize ? (
                  "Touchpoint"
                ) : (
                  <FormControl sx={{ width: 120 }} size="small">
                    <InputLabel id="touchpoint-select-label">
                      Touchpoint
                    </InputLabel>
                    <Select
                      labelId="touchpoint-select-label"
                      label="Touchpoint"
                      id="touchpoint-select"
                      defaultValue="all"
                      size="small"
                      name="touchpoint"
                      onChange={(e: any) =>
                        setSelectedTouchpoint(e.target.value)
                      }
                      sx={{ textTransform: "capitalize", maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {touchpoints.map((i: any) => (
                        <MenuItem
                          key={i}
                          value={i}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 200 }}>
                {summarize ? (
                  "Locale"
                ) : (
                  <FormControl sx={{ m: 1, width: "98%" }} size="small">
                    <InputLabel id="locale-select-label">Locale</InputLabel>
                    <Select
                      defaultValue="all"
                      onChange={(e: any) => setSelectedLocale(e.target.value)}
                      labelId="locale-select-label"
                      id="locale-select"
                      label="Locale"
                      name="locale"
                      value={selectedLocale}
                      disabled={
                        activeLocales.length === 0 || selectedBrand === 0
                      }
                    >
                      <MenuItem value="all">All</MenuItem>
                      {activeLocales
                        .filter(
                          (locale: {
                            name: string;
                            id: number;
                            brand_id: number;
                          }) => locale.brand_id === selectedBrand
                        )
                        .map((item: { name: string; id: number }) => {
                          return (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 50 }}>
                {summarize ? (
                  "Page"
                ) : (
                  <FormControl sx={{ width: 100 }} size="small">
                    <InputLabel id="page-select-label">Page</InputLabel>
                    <Select
                      labelId="page-select-label"
                      label="Page"
                      id="page-select"
                      defaultValue="all"
                      size="small"
                      name="page"
                      onChange={(e: any) => setSelectedPage(e.target.value)}
                      sx={{ maxWidth: "130px" }}
                    >
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                      {config.decision_pages.map((i: any) => (
                        <MenuItem key={i.id} value={i.name}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
              <TableCell sx={{ width: 50 }}>Variations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liveTableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No live rollouts
                </TableCell>
              </TableRow>
            ) : (
              liveTableData.slice(0, limit).map((item: any) => (
                <TableRow
                  className="experiment-list-row"
                  key={item.rule_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={(e) => {
                    navigate(`/features/${item.flag_id}/${item.rule_id}`);
                  }}
                >
                  <TableCell component="th" scope="row" className="rule_id">
                    <Typography variant="caption">{item.rule_id}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.rule_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(item.rule_started).format("YYYY-MM-DD HH:mm")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getBrandName(item.brand_id)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.team_name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {getTouchpoints(item)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getLocales(item)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.page}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.variations}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5">Live rollouts: {liveData.length}</Typography>
          {isLoaded ? liveExperimentsList(5) : <CenteredSpinner />}
          <Typography variant="body2" color="textSecondary" align="right">
            <Button disabled={liveTableData.length < 6} onClick={handleOpen}>
              More...
            </Button>
          </Typography>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "99%", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>Live rollouts</DialogTitle>
        <DialogContent>{liveExperimentsList(999)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CurrentLive;
