import { MouseEvent } from "react";
import { Box, Button } from "@mui/material";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { useTheme } from "@mui/material/styles";

type ButtonProps = {
  style: Object;
  handleClick: (event: MouseEvent) => void;
  disableGraph: boolean;
};

export const ExpandGraphButton = (props: ButtonProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        disabled={props.disableGraph}
        size="small"
        variant="contained"
        onClick={props.handleClick}
        sx={{
          borderRadius: "25px",
          pl: 0,
          pr: 0,
          minWidth: "35px",
          backgroundColor: theme.palette.background.default,
          "&:hover": { backgroundColor: "#dddddd" },
          position: "absolute",
          mt: "5px",
          display: props.disableGraph ? "none" : "inline-flex",
        }}
      >
        <SsidChartIcon color="action" />
      </Button>
    </Box>
  );
};
