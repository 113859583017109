import getSid from "./getSid";

const sendEvent = (eventName: string, eventType: string, params: any) => {
  const url: string = process.env.REACT_APP_DEP_URL + "/v1/internal";
  const sid = getSid();
  const out = {
    eventName: eventName,
    eventType: eventType,
    parameters: params,
    sId: sid,
    source: "fabulous-dashboard",
  };
  fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(out),
  }).catch((error) => {
    console.log(error);
  });
};

export default sendEvent;
