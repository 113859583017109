import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useConfirm } from "material-ui-confirm";

const ButtonBack: React.FC<any> = ({ link, text, confirmation }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const backToFlags = () => {
    if (confirmation) {
      confirm(confirmation)
        .then(() => {
          navigate(link);
        })
        .catch(() => {
          console.log("Cancelled");
        });
    } else {
      navigate(link);
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={backToFlags}
      startIcon={<ArrowBackIcon />}
    >
      {text}
    </Button>
  );
};

export default ButtonBack;
