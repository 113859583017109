import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByRegion } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import LaunchedByGraph from "./LaunchedByGraph";

const LaunchedByRegion: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const { config } = useConfig();
  const [regionsInChart, setRegionsInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  useEffect(() => {
    if (config.regions.length > 0) {
      FetchStatsLaunchedByRegion((output: any) => {
        setIsLoaded(true);
        const all: any = [];
        const allRegions: any = [];
        output.response.forEach((item: any) => {
          const obj = all.find((a: any) => a.month === item.month) || {
            month: item.month,
          };

          obj[item.region_key] = item.experiments_started;
          if (!allRegions.includes(item.region_key)) {
            allRegions.push(item.region_key);
          }
          const index: number = all.findIndex(
            (a: any) => a.month === item.month
          );
          if (index === -1) {
            all.push(obj);
          } else {
            all[index] = obj;
          }
        });
        setLaunchData(all);
        setRegionsInChart(allRegions);
        const palette = ColorPalette(allRegions.length);
        const colors: any = [];
        allRegions.forEach((r: any, idx: number) => {
          colors[r] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config]);

  const getRegionName = (region_key: string) => {
    const region: any = config.regions.find(
      (t: any) => t.region_key === region_key
    );
    return region ? region.name : region_key;
  };
  const tooltipFormatter = (value: any, name: any) => {
    return [value, getRegionName(name)];
  };

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{getRegionName(value)}</span>;
  };

  return (
    <LaunchedByGraph
      title="Experiments by Region"
      isLoaded={isLoaded}
      data={launchData}
      tooltipFormatter={tooltipFormatter}
      itemsInChart={regionsInChart}
      legendFormatter={legendFormatter}
      chartColors={chartColors}
    />
  );
};

export default LaunchedByRegion;
