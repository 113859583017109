import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByTouchpoint } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import LaunchedByGraph from "./LaunchedByGraph";

const LaunchedByTouchpoint: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { config } = useConfig();
  const [launchData, setLaunchData] = useState([]);
  const [tpsInChart, setTpsInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  (window as any).launchData = launchData;

  useEffect(() => {
    if (config.touchpoints.length > 0 && isLoaded === false) {
      FetchStatsLaunchedByTouchpoint((output: any) => {
        setIsLoaded(true);
        const all: any = [];
        const allTps: any = [];
        output.response.forEach((item: any) => {
          const obj = all.find((a: any) => a.month === item.month) || {
            month: item.month,
          };
          const tp: any = config.touchpoints.find(
            (t: any) => t.name === item.touchpoint.toLowerCase()
          );
          obj[tp.name] = item.experiments_started;
          if (!allTps.includes(tp.name)) {
            allTps.push(tp.name);
          }
          const index: number = all.findIndex(
            (a: any) => a.month === item.month
          );
          if (index === -1) {
            all.push(obj);
          } else {
            all[index] = obj;
          }
        });
        setLaunchData(all);
        setTpsInChart(allTps);
        const palette = ColorPalette(allTps.length);
        const colors: any = [];
        allTps.forEach((brand: any, idx: number) => {
          colors[brand] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config, isLoaded]);

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color, textTransform: "capitalize" }}>{value}</span>;
  };

  const tooltipFormatter = (value: any, name: any) => {
    return [value, name[0].toUpperCase() + name.slice(1).toLowerCase()];
  };
  return (
    <LaunchedByGraph
      title="Experiments by Touchpoint"
      isLoaded={isLoaded}
      data={launchData}
      tooltipFormatter={tooltipFormatter}
      itemsInChart={tpsInChart}
      legendFormatter={legendFormatter}
      chartColors={chartColors}
    />
  );
};

export default LaunchedByTouchpoint;
