import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import {
  AdminFetchAudiences,
  AdminUpdateAudience,
  AdminCreateAudience,
  AdminArchiveAudience,
} from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useConfirm } from "material-ui-confirm";
import { useConfig } from "../context";

const Audiences: React.FC<{}> = () => {
  const [audiences, setAudiences] = React.useState([]);
  const [editOpen, setEditOpen] = React.useState(false);

  const [saving, setSaving] = React.useState(false);
  const [selectedAudience, setSelectedAudience] = React.useState({
    name: "",
    attribute: "",
    values: "",
    brand_id: 0,
    matchType: "",
    description: "",
    id: 0,
  });
  (window as any).selectedAudience = selectedAudience;
  const confirm = useConfirm();
  const { config } = useConfig();

  const getBrandName = (id: any) => {
    const brand = config?.brands?.find((brand: any) => brand.id === id);
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  useEffect(() => {
    AdminFetchAudiences((output: any) => {
      setAudiences(output.response);
    });
  }, []);

  const handleEdit = (audience_id: number) => {
    const audience = audiences.find((a: any) => a.id === audience_id);
    if (audience) {
      setSelectedAudience(audience);
      setEditOpen(true);
    } else {
      console.log("Audience not found");
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSelectedAudience((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    setSaving(true);
    if (selectedAudience.id === 0) {
      AdminCreateAudience((output: any, error: any) => {
        if (output.status === 200) {
          setSaving(false);
          setEditOpen(false);
          setAudiences([]);
          AdminFetchAudiences((output: any) => {
            setAudiences(output.response);
          });
        }
      }, selectedAudience);
    } else {
      AdminUpdateAudience((output: any, error: any) => {
        if (output.status === 200) {
          setSaving(false);
          setEditOpen(false);
          setAudiences([]);
          AdminFetchAudiences((output: any) => {
            setAudiences(output.response);
          });
        }
      }, selectedAudience);
    }
  };
  const handleCreate = () => {
    setSelectedAudience({
      name: "",
      attribute: "",
      values: "",
      brand_id: 0,
      matchType: "",
      description: "",
      id: 0,
    });
    setEditOpen(true);
  };

  const handleArchive = (audience_id: string) => {
    const audience = audiences.find((a: any) => a.id === audience_id);
    if (audience) {
      confirm({
        title: "Archive audience",
        description: "Are you sure you want to archive this audience?",
        confirmationButtonProps: { color: "error", variant: "contained" },
      })
        .then(() => {
          AdminArchiveAudience((output: any) => {
            if (output.status === 200) {
              setAudiences([]);
              AdminFetchAudiences((output: any) => {
                setAudiences(output.response);
              });
            }
          }, Number(audience_id));
        })
        .catch(() => {
          console.log("Cancelled");
        });
    }
  };
  return (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-around">
          <Grid item xs={8}>
            <Typography variant="h5">Audiences</Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={saving}
              onClick={handleCreate}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12} container>
            {audiences.length === 0 ? (
              <Grid item xs={12}>
                <CenteredSpinner />
              </Grid>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Attribute</TableCell>
                      <TableCell>Values</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audiences.map((audience: any) => (
                      <TableRow key={audience.id}>
                        <TableCell>
                          {audience.name}
                          <br />
                          <span style={{ fontSize: "smaller" }}>
                            {audience.description}
                          </span>
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Monospace", fontSize: "small" }}
                        >
                          {audience.attribute}
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Monospace", fontSize: "small" }}
                        >
                          {audience.values}
                        </TableCell>
                        <TableCell>{getBrandName(audience.brand_id)}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(audience.id)}>
                            <CreateIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleArchive(audience.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={editOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => setEditOpen(false)}
      >
        <DialogTitle>Edit audience</DialogTitle>

        <DialogContent sx={{ pt: "20px!important" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Name"
                name="name"
                value={selectedAudience.name}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Description"
                name="description"
                value={selectedAudience.description}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="brand-select-label">Brand</InputLabel>
                <Select
                  labelId="brand-select-label"
                  id="brand-select"
                  name="brand_id"
                  label="Brand"
                  onChange={handleChange}
                  defaultValue={0}
                  value={selectedAudience.brand_id}
                >
                  <MenuItem value={0}>Choose brand</MenuItem>
                  {config.brands?.map((brand: any) => (
                    <MenuItem value={brand.id} key={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Attribute"
                name="attribute"
                value={selectedAudience.attribute}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="matchtype_label">Matchtype</InputLabel>
                <Select
                  labelId="matchtype_label"
                  label="Matchtype"
                  name="matchType"
                  id="matchtype"
                  value={selectedAudience.matchType}
                  onChange={handleChange}
                >
                  <MenuItem key={0} value="eq">
                    Is
                  </MenuItem>
                  <MenuItem key={1} value="ne">
                    Is NOT
                  </MenuItem>
                  <MenuItem key={2} value="sub">
                    Contains
                  </MenuItem>
                  <MenuItem key={3} value="nosub">
                    Contains NOT
                  </MenuItem>
                  <MenuItem key={4} value="gt">
                    Greater than
                  </MenuItem>
                  <MenuItem key={5} value="lt">
                    Less than
                  </MenuItem>
                  <MenuItem key={6} value="gte">
                    Greater than or equal
                  </MenuItem>
                  <MenuItem key={7} value="lte">
                    Less than or equal
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ width: "100%" }}
                label="Values"
                name="values"
                value={selectedAudience.values}
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSave} disabled={saving}>
            Save
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Audiences;
