import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";

import ScheduleIcon from "@mui/icons-material/Schedule";
import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { UpdateSchedule } from "../helpers/FetchData";
import { enqueueSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

const Scheduler: React.FC<any> = ({
  schedule,
  ruleId,
  updateParent,
  preventSchedule,
}) => {
  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [stopDate, setStopDate] = useState<Moment | null>(null);
  const [modalstartDate, setModalstartDate] = useState<Moment | null>(null);
  const [modalstopDate, setModalstopDate] = useState<Moment | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (schedule) {
      const start = schedule.find((i: any) => i.action === "start");
      const stop = schedule.find((i: any) => i.action === "stop");
      setModalstartDate(start ? moment(start?.action_time) : null);
      setModalstopDate(stop ? moment(stop.action_time) : null);
      setStartDate(start ? moment(start?.action_time) : null);
      setStopDate(stop ? moment(stop.action_time) : null);
    }
  }, [schedule]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      //setStartDate(null);
      //setStopDate(null);
      setOpen(false);
    } else {
      if (!preventSchedule()) {
        setOpen(true);
      }
    }
  };

  const handleSave = () => {
    setLoading(true);

    const schedule = [
      {
        action_time: modalstartDate?.isValid()
          ? modalstartDate.format("YYYY-MM-DD HH:mm")
          : "",
        action: "start",
      },
      {
        action_time: modalstopDate?.isValid()
          ? modalstopDate.format("YYYY-MM-DD HH:mm")
          : "",
        action: "stop",
      },
    ];

    UpdateSchedule(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar("Could not set schedule!", { variant: "error" });
        } else {
          enqueueSnackbar("Schedule updated!", { variant: "success" });          
          setOpen(false);
          setStartDate(modalstartDate);
          setStopDate(modalstopDate);
          updateParent(Date.now().toString());
        }
        setLoading(false);
      },
      ruleId,
      schedule,
    );
  };

  const scheduleTooltip = () => {
    let tooltip = "";

    if (!startDate?.isValid() && !stopDate?.isValid()) {
      tooltip = preventSchedule()
        ? "You must configure the experiment propely before setting the schedule!"
        : "No schedule set";
    } else {
      tooltip =
        "Start: " +
        (startDate?.isValid()
          ? startDate?.format("YYYY-MM-DD HH:mm")
          : "not scheduled") +
        "\n";
      tooltip +=
        "Stop: " +
        (stopDate?.isValid()
          ? stopDate?.format("YYYY-MM-DD HH:mm")
          : "not scheduled");
    }
    return tooltip;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Tooltip
        title={
          <div style={{ whiteSpace: "pre-line" }}>{scheduleTooltip()}</div>
        }
      >
        <IconButton onClick={handleOpen}>
          <ScheduleIcon color={schedule ? "primary" : "action"} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleOpen} maxWidth="md">
        <DialogTitle>Schedule start / stop </DialogTitle>

        <DialogContent>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} variant="body2">
                Schedule the start and/or stop of the experiment.{" "}
                <strong>All times are in UTC!.</strong>
              </Typography>
            </Grid>
            <Grid item xs="auto" container>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Start date</Typography>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm"
                  ampm={false}
                  value={modalstartDate}
                  onChange={(newValue: any) => setModalstartDate(newValue)}
                  slotProps={{
                    actionBar: { actions: ["clear", "accept"] },
                    shortcuts: {
                      items: [
                        {
                          label: "Start of day",
                          getValue: () => {
                            return startDate
                              ? moment(startDate).startOf("day")
                              : null;
                          },
                        },
                        {
                          label: "End of day",
                          getValue: () => {
                            return startDate
                              ? moment(startDate).endOf("day")
                              : null;
                          },
                        },
                      ],
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs="auto" container>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Stop date</Typography>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm"
                  ampm={false}
                  value={modalstopDate}
                  onChange={(newValue: any) => setModalstopDate(newValue)}
                  slotProps={{
                    actionBar: { actions: ["clear", "accept"] },
                    shortcuts: {
                      items: [
                        {
                          label: "Start of day",
                          getValue: () => {
                            return stopDate
                              ? moment(stopDate).startOf("day")
                              : null;
                          },
                        },
                        {
                          label: "End of day",
                          getValue: () => {
                            return stopDate
                              ? moment(stopDate).endOf("day")
                              : null;
                          },
                        },
                      ],
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>Cancel</Button>
          <LoadingButton
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default Scheduler;
