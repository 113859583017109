import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import GenerateContent from "../GenerateContent";

const AiText: React.FC<any> = ({
  var_key,
  onChangeVariables,
  label,
  indexes,
  variable,
  ruleData,
}) => {
  return (
    <div key={var_key + "" + indexes.variIndex}>
      <TextField
        key={var_key + "" + indexes.variIndex}
        margin="dense"
        value={variable.value || ""}
        name={var_key}
        label={label}
        type="text"
        fullWidth
        onChange={(e) =>
          onChangeVariables(e, {
            ...indexes,
          })
        }
        InputProps={
          indexes.baseIndex > 0 &&
          ruleData.meta &&
          ruleData.meta.version >= 4 &&
          ruleData.type === "mab"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <GenerateContent
                      input={
                        ruleData.variations[0].variables.find(
                          (i: any) => i.id === variable.id
                        ).value
                      }
                      output={(output: any) =>
                        onChangeVariables(
                          { type: "text", target: { value: output } },
                          indexes
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </div>
  );
};

export default AiText;
