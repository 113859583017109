import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Badge,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HelpToolTip from "../components/common/HelpToolTip";
import { useEffect, useState } from "react";

export const MabResult = (props: any) => {
  const {
    experimentData,
    bucketedVisitors,
    getVariationName,
    getVariationColor,
    config,
  } = props;
  const theme = useTheme();
  const [metric, setMetric] = useState<any>(false);

  useEffect(() => {
    const m = experimentData.metrics[0];
    m.variations.sort((a: any, b: any) => a.variationId - b.variationId);
    setMetric(experimentData.metrics[0]);
  }, [experimentData]);

  useEffect(() => {
    setMetric((prev: any) => {
      prev.variations.forEach((variation: any) => {
        const variationInfo = bucketedVisitors.variations.find((i: any) => {
          return Number(i.variationId) === Number(variation.variationId);
        });
        variation.visitors = variationInfo ? variationInfo.number_bucketed : 0;
      });

      return { ...prev, variations: prev.variations };
    });
  }, [bucketedVisitors]);

  const formatValue = (mean_value: number, metric: any) => {
    switch (metric.type) {
      case "prop":
        return (mean_value * 100).toFixed(2) + "%";
      case "means":
        const suffix = metric.event === "purchase" ? " SEK" : "";
        return mean_value.toFixed(2) + suffix;
      case "count":
        return mean_value.toFixed(2);
      case "items":
        return mean_value.toFixed(2);
      default:
        break;
    }
  };

  const getConversionLabel = (type: string) => {
    switch (type) {
      case "means":
        return (
          <>
            <b>Value</b>
            <HelpToolTip text={config.cms_strings.results.value_tooltip} />
          </>
        );
      case "count":
        return (
          <>
            <b>Conversions per visitor</b>
            <HelpToolTip text={config.cms_strings.results.conversion_tooltip} />
          </>
        );
      case "items":
        return (
          <>
            <b>Items per visitor</b>
            <HelpToolTip text={config.cms_strings.results.items_tooltip} />
          </>
        );
      default:
        return (
          <>
            <b>Conversion Rate</b>
            <HelpToolTip
              text={config.cms_strings.results.conversio_rate_tooltip}
            />
          </>
        );
    }
  };

  return !metric ? (
    <></>
  ) : (
    <Stack direction="column" spacing={3} sx={{ marginTop: "50px" }}>
      <Card variant="outlined">
        <CardHeader
          title={metric.name}
          titleTypographyProps={{
            variant: "button",
            fontWeight: "bold",
            noWrap: true,
          }}
          subheader={"Metric type: " + metric.category.toUpperCase()}
          subheaderTypographyProps={{ variant: "caption" }}
          sx={{
            backgroundColor: props.statusColor,
            maxHeight: "56px",
            width: "100%",
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        />
        <CardContent>
          <TableContainer sx={{ marginTop: "-20px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontSize={10}>
                      Event name: {metric.event}
                    </Typography>
                    <Typography fontSize={10}>
                      Desired impact: {metric.winning_direction || "positive"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" vertical-align="bottom">
                    <Grid container alignItems="center" justifyContent="right">
                      {config.cms_strings
                        ? getConversionLabel(metric.type)
                        : ""}
                    </Grid>
                  </TableCell>
                  <TableCell align="right" vertical-align="bottom">
                    <Grid container alignItems="center" justifyContent="right">
                      {" "}
                      <b>Converting visitors </b>
                      <HelpToolTip
                        text={
                          config.cms_strings.results.converting_visitors_tooltip
                        }
                      ></HelpToolTip>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metric.variations.map((variation: any) => {
                  return (
                    <TableRow key={variation.variationId}>
                      <TableCell component="th" scope="row">
                        <Grid
                          container
                          justifyContent="flex-start"
                          sx={{ flexWrap: "nowrap" }}
                        >
                          <Grid item xs="auto">
                            <Typography noWrap={true} variant="subtitle2">
                              {getVariationName(Number(variation.variationId))}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Badge
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: getVariationColor(
                                    variation.variationId
                                  ),
                                },
                              }}
                              variant="dot"
                            >
                              <span style={{ marginLeft: 10 }}>&nbsp;</span>
                            </Badge>
                          </Grid>
                        </Grid>
                        <Typography fontSize={11}>
                          Visitors: {variation.visitors}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formatValue(variation.mean_value, metric)}
                      </TableCell>
                      <TableCell align="right">
                        {variation.conversions_on_the_metric}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Stack>
  );
};
