import { Grid } from "@mui/material";
import { VariationAccordionItem } from "./VariationAccordionItem";
import { MetricsTable } from "./MetricsTable";

export const AbtestResult = (props: any) => {
  const {
    experimentData,
    bucketedVisitors,
    isSegmented,
    getVariationName,
    getVariationColor,
  } = props;

  const getVariationStatus = (variationId: number) => {
    const isSuccess = isSignificantSuccess(variationId);
    const significantGuardrail = isSignificantGuardrail(variationId);

    return !significantGuardrail && isSuccess
      ? "winner"
      : significantGuardrail
      ? "loser"
      : "inconclusive";
  };

  const isSignificantGuardrail = (variationId: any) => {
    const guardRails = experimentData.metrics.filter((i: any) => {
      return i.category === "guardrail";
    });
    return guardRails.some((d: any) => {
      return d.variations.length > 0 &&
        d.variations[variationId] &&
        d.variations[variationId].hasOwnProperty("stats")
        ? d.variations[variationId].stats.statSig === true
        : false;
    });
  };

  const isSignificantSuccess = (variationId: any) => {
    const successMetrics = experimentData.metrics.filter((i: any) => {
      return i.category === "success";
    });
    return successMetrics.some((d: any) => {
      return d.variations.length > 0 &&
        d.variations[variationId] &&
        d.variations[variationId].hasOwnProperty("stats")
        ? d.variations[variationId].stats.statSig === true &&
            d.variations[variationId].stats.isWinner === true
        : false;
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          spacing={3}
          sx={{
            width: "100%",
            marginLeft: "0px",
            paddingLeft: "0px",
            marginTop: "25px",
          }}
        >
          {/* DETAILS END*/}

          <Grid item xs={12} md={12} sm={12} lg={12} alignContent="flex-end">
            {/* VARIATION HIGHLIGHTS */}
            {experimentData.variations.map((variation: any) => {
              if (variation.id > 0) {
                const guardrailMetrics = experimentData.metrics.filter(
                  (metric: any) => {
                    if (metric.category === "guardrail") {
                      const guardrailVariation = metric.variations.filter(
                        (vari: any) => vari.variationId === variation.id
                      );
                      return {
                        metricName: metric.metricName,
                        ...guardrailVariation[0],
                      };
                    } else {
                      return false;
                    }
                  }
                );

                const successMetrics = experimentData.metrics.filter(
                  (metric: any) => metric.category === "success"
                );
                const variationStatus = getVariationStatus(variation.id);

                return (
                  <VariationAccordionItem
                    successMetrics={successMetrics}
                    guardrailMetrics={guardrailMetrics}
                    smallHeading="Success Metric"
                    mainHeading="Checkout Rate"
                    name={getVariationName(variation.id)}
                    defaultExpanded={
                      variationStatus === "winner" ? true : false
                    }
                    variationStatus={variationStatus}
                    guardRailFail={isSignificantGuardrail(variation.id)}
                    usersInVariation={bucketedVisitors}
                    variationId={variation.id}
                    key={variation.id}
                    isSegmented={isSegmented}
                  />
                );
              } else {
                return null;
              }
            })}

            {/* VARIATION HIGHLIGHTS END*/}
          </Grid>
        </Grid>
      </Grid>

      <MetricsTable
        experimentData={experimentData}
        bucketedVisitors={bucketedVisitors}
        getVariationName={getVariationName}
        getVariationColor={getVariationColor}
        disableGraph={isSegmented}
        mockEnabled={props.mockEnabled}
        isSegmented={isSegmented}
      />
    </>
  );
};
