import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import YTicker from "./YTicker";
import CenteredSpinner from "../components/common/CenteredSpinner";
import { useTheme } from "@mui/material/styles";

const LaunchedByGraph: React.FC<any> = (props: any) => {
  const {
    isLoaded,
    title,
    data,
    tooltipFormatter,
    itemsInChart,
    legendFormatter,
    chartColors,
  } = props;
  const theme = useTheme();

  const tooltipStyle = {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    fontSize: 12,
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        {!isLoaded ? (
          <CenteredSpinner />
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis height={40} dataKey="month" tick={<YTicker />} />
              <CartesianGrid />
              <Tooltip
                formatter={tooltipFormatter}
                contentStyle={tooltipStyle}
                itemStyle={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                cursor={{ fill: "#b8e18f", fillOpacity: 0.3 }}
              />
              {itemsInChart.map((item: any) => (
                <Bar
                  stackId="a"
                  key={item}
                  dataKey={item}
                  fill={chartColors[item]}
                />
              ))}

              <Legend
                wrapperStyle={{ fontSize: 12, right: -10 }}
                formatter={legendFormatter}
                layout="vertical"
                align="right"
                verticalAlign="top"
              />
              <YAxis fontSize={12}>
                <Label
                  value="Started per month"
                  angle={-90}
                  position="outside"
                  fill="#676767"
                  fontSize={14}
                  dx={-20}
                />
              </YAxis>
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default LaunchedByGraph;
