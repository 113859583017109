import React from "react";
import { useTheme } from "@mui/material/styles";
interface StatsigContainerProps {
  stats: any;
  conf_min: number | null;
  conf_max: number | null;
  containerWidth: number;
}

const StatsigContainer: React.FC<StatsigContainerProps> = ({
  stats,
  conf_min,
  conf_max,
  containerWidth,
}) => {
  // conf_min = -120.123; //for testing purposes
  // conf_max = null; //for testing purposes
  const theme = useTheme();
  const conf_min_copy = conf_min;
  const conf_max_copy = conf_max;

  conf_min = conf_min !== null && conf_min < -100 ? -100 : conf_min;
  conf_max = conf_max !== null && conf_max > 100 ? 100 : conf_max;

  const containerHeight = containerWidth / 8;
  const middleLineWidth = containerWidth / 100;

  const middleLineLeft = containerWidth / 2 - middleLineWidth / 2; // Adjusting to center the line
  const barRightLeft = containerWidth / 2;

  const scale = containerWidth / 200;
  let barWidth = 0;

  if (conf_min !== null) {
    barWidth = barRightLeft - conf_min * scale;
  } else if (conf_max !== null) {
    barWidth = barRightLeft + conf_max * scale;
  }

  const containerStyles: React.CSSProperties = {
    width: `${containerWidth}px`,
    height: `${containerHeight}px`,
    position: "relative",
    border: `1px solid ${theme.palette.text.primary}`,
    //align self right
    marginLeft: "auto",
    borderRadius: "5px",
  };

  const middleLineStyles: React.CSSProperties = {
    zIndex: 999,
    width: `${middleLineWidth}px`,
    height: "100%",
    backgroundColor: theme.palette.text.primary,
    position: "absolute",
    left: `${middleLineLeft}px`,
  };

  const barStyles: React.CSSProperties = {
    height: "100%",
    position: "absolute",
    backgroundColor: `${
      stats.statSig
        ? stats.isWinner
          ? theme.palette.success.light
          : theme.palette.error.light
        : theme.palette.divider
    }`,
    width: `${barWidth}px`,
    right: conf_min !== null ? "0" : undefined,
    left: conf_max !== null ? "0" : undefined,
    borderRadius: "5px",
  };

  const minLabelDivStyles: React.CSSProperties = {
    position: "absolute",
    top: "4px",
    left: "0",
    borderLeft:
      conf_min_copy !== null
        ? `1px dashed ${theme.palette.text.primary}`
        : "none",
    height: "10px",
  };

  const minLabelStyles: React.CSSProperties = {
    fontSize: `12px`,
    left: conf_min_copy !== null ? "50%" : "-8px",
    transform: "translateX(-50%)",
    position: "absolute",
    top: conf_min_copy !== null ? "10px" : "-7px",
  };

  const maxLabelDivStyles: React.CSSProperties = {
    position: "absolute",
    top: "4px",
    right: "0",
    borderLeft:
      conf_max_copy !== null
        ? `1px dashed ${theme.palette.text.primary}`
        : "none",
    height: "10px",
  };

  const maxLabelStyles: React.CSSProperties = {
    fontSize: `12px`,
    left: conf_max_copy !== null ? "50%" : "8px",
    transform: "translateX(-50%)",
    position: "absolute",
    top: conf_max_copy !== null ? "10px" : "-7px",
  };

  return (
    <div style={containerStyles}>
      <div style={middleLineStyles}>
        <div
          style={{
            position: "absolute",
            top: `-${containerHeight + 6}px`,
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: `12px`,
          }}
        >
          0
        </div>
      </div>
      <div style={barStyles}>
        <div style={minLabelDivStyles}>
          <div style={minLabelStyles}>
            {conf_min_copy !== null
              ? conf_min_copy > 100
                ? "-100"
                : (conf_min_copy as number).toFixed(2)
              : "∞"}
          </div>
        </div>
        <div style={maxLabelDivStyles}>
          <div style={maxLabelStyles}>
            {conf_max_copy !== null
              ? (conf_max_copy > 0 ? "+" : "") +
                (conf_max_copy > 100
                  ? "100"
                  : (conf_max_copy as number).toFixed(2))
              : "∞"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsigContainer;
