import sendEvent from "./sendEvent";

const interaction = (event: string, params?: []) => {
  const eventParams = [{ name: "page", stringValue: window.location.pathname }];
  if (params) {
    params.forEach((param) => {
      eventParams.push(param);
    });
  }
  sendEvent(event, "interaction", eventParams);
};

export default interaction;
