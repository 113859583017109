import React from "react";
import { Typography } from "@mui/material";

const Welcome: React.FC<{}> = () => {
  return (
    <>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
      ></Typography>
    </>
  );
};

export default Welcome;
