import { Card, CardHeader, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Scorecard = (props: any) => {
  const theme = useTheme();
  return (
    <Card variant="outlined" sx={props.customSx}>
      <CardHeader
        title={props.mainHeading}
        titleTypographyProps={{
          variant: "button",
          fontWeight: "bold",
          noWrap: true,
        }}
        subheader={props.smallHeading}
        subheaderTypographyProps={{ variant: "caption" }}
        sx={{
          backgroundColor: props.statusColor,
          maxHeight: "56px",
          width: "100%",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};
