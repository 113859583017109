import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ChangeDecisionPages: React.FC<any> = ({
  currentPages,
  handlePageChange,
  editDisabled,
  size,
}) => {
  const { config } = useConfig();
  const [selected, setSelected] = useState<any[]>([]);
  const theme = useTheme();
  const wide = useMediaQuery(theme.breakpoints.up("md"));
  (window as any).selected = selected;
  useEffect(() => {
    setSelected(currentPages);
  }, [currentPages]); // eslint-disable-line

  const onChange = (event: any, value: any) => {
    //setSelected(value);
    const pages = {
      target: {
        name: "decision_page",
        value: value,
        type: "decision_page",
      },
    };

    handlePageChange(pages);
  };
  //(window as any).selected = selected;
  if (Object.keys(config).length === 0) return <div>Loading pages</div>; // Waiting for config
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs="auto">
        <Typography variant="caption">Experiment page</Typography>
      </Grid>
      <Grid item xs={2}>
        <HelpToolTip
          text={config.cms_strings.experiments.decision_page_tooltip}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          defaultValue={[]}
          limitTags={wide ? 4 : 2}
          fullWidth={true}
          onChange={onChange}
          value={selected}
          size={size ? size : ""}
          sx={size && size === "small" && { p: 1 }}
          multiple
          id="checkboxes-pages"
          options={config.decision_pages}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, selectedValue) =>
            option.id === selectedValue.id
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} />}
          ChipProps={{ deleteIcon: <></> }}
          disabled={editDisabled}
        />
      </Grid>
    </Grid>
  );
};

export default ChangeDecisionPages;
