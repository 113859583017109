import React from "react";

import { Skeleton } from "@mui/material";

const Loader: React.FC<{}> = () => {
  return (
    <>
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
      <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
    </>
  );
};

export default Loader;
