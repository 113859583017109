import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { FetchStatsLaunched } from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";

import {
  AreaChart,
  Bar,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  BarChart,
  Label,
} from "recharts";
import YTicker from "./YTicker";
import ColorPalette from "../helpers/ColorPalette";
import { useTheme } from "@mui/material/styles";

const Launched: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const [accuLaunchData, setAccuLaunchData] = useState([]);
  const palette = ColorPalette(3);
  const theme = useTheme();

  const tooltipStyle = {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    fontSize: 12,
  };

  const getAccumulatedData = (data: any) => {
    let accumulated = 0;
    return data.map((entry: any) => {
      accumulated +=
        Number(entry.experiments_started) + Number(entry.mabs_started);
      return { month: entry.month, started: accumulated };
    });
  };
  useEffect(() => {
    FetchStatsLaunched((output: any) => {
      setIsLoaded(true);
      setLaunchData(output.response);

      setAccuLaunchData(getAccumulatedData(output.response));
    });
  }, []);

  const tooltipFormatter = (value: any, key: any) => {
    if (key === "started") {
      return [value, "Accumulated"];
    } else if (key === "mabs_started") {
      return [value, "Multi-Armed Bandits"];
    }
    return [value, "A/B-Tests"];
  };

  return (
    <Grid container spacing={2} justifyContent="flex-starts">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5">Experiments launched</Typography>
            {!isLoaded ? (
              <CenteredSpinner />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={launchData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="month" tick={<YTicker />} />
                  <CartesianGrid />
                  <Tooltip
                    formatter={tooltipFormatter}
                    contentStyle={tooltipStyle}
                    itemStyle={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                    cursor={{ fill: "#b8e18f", fillOpacity: 0.3 }}
                  />

                  <YAxis fontSize={12} yAxisId="left">
                    <Label
                      value="Started per month"
                      angle={-90}
                      position="outside"
                      fill="#676767"
                      fontSize={14}
                      dx={-20}
                    />
                  </YAxis>
                  <Bar
                    dataKey="experiments_started"
                    stackId="a"
                    fill={palette(0)}
                    yAxisId="left"
                  />
                  <Bar
                    yAxisId="left"
                    dataKey="mabs_started"
                    stackId="a"
                    fill={palette(1)}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
      {
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">
                Experiments launched accumulated
              </Typography>
              {!isLoaded ? (
                <CenteredSpinner />
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    width={500}
                    height={300}
                    data={accuLaunchData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="month" tick={<YTicker />} />
                    <CartesianGrid />
                    <Tooltip
                      formatter={tooltipFormatter}
                      contentStyle={tooltipStyle}
                      itemStyle={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                      cursor={{ fill: "#b8e18f", fillOpacity: 0.3 }}
                    />

                    <YAxis fontSize={12}>
                      <Label
                        value="Started per month"
                        angle={-90}
                        position="outside"
                        fill="#676767"
                        fontSize={14}
                        dx={-20}
                      />
                    </YAxis>
                    <Area dataKey="started" fill={palette(0)} type="monotone" />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Grid>
      }
    </Grid>
  );
};

export default Launched;
