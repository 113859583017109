import React from "react";
import { Grid } from "@mui/material";
import TimeChart from "./TimeChart";
const Watchtower: React.FC<any> = (props: any) => {
  return (
    <Grid container spacing={2} justifyContent="flex-starts">
      <Grid item xs={12}>
        <TimeChart />
      </Grid>
    </Grid>
  );
};

export default Watchtower;
