import React from "react";
import { Typography, Grid, Divider, Box } from "@mui/material";
import step1 from "../assets/pages/1.png";
import step2 from "../assets/pages/2.png";
import step3 from "../assets/pages/3.png";
import step4 from "../assets/pages/4.png";
import step5 from "../assets/pages/5.png";
import step6 from "../assets/pages/6.png";
import step7 from "../assets/pages/7.png";
import step8 from "../assets/pages/8.png";
import step9 from "../assets/pages/9.png";
import step10 from "../assets/pages/10.png";
import Image from "../components/common/Image";

const ContentSquareSegments: React.FC<{}> = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          How to create Fabulous segments in ContentSquare
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography variant="h6">In Fabulous</Typography>
        <br />
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 1</Typography>
            <Typography>
              In the experiment you will find the Experiment-ID
            </Typography>
            <Image src={step1} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 2</Typography>
            <Typography>
              The variations in each experiment have the same structure:
            </Typography>
            <ul>
              <li>Control: 0</li>
              <li>Variation 1: 1</li>
              <li>Variation 2: 2</li>
            </ul>

            <Image src={step2} />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid item xs={12}>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography variant="h6">In ContentSquare</Typography>
        <br />
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 1</Typography>
            <Typography>
              Go to <strong>Define</strong> and then <strong>Segment</strong>
            </Typography>
            <Image src={step3} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 2</Typography>
            <Typography>
              Click on <strong>New Segment</strong>
            </Typography>
            <Image src={step4} />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 3</Typography>
            <Typography>
              Under <strong>Name</strong> enter:
            </Typography>
            <ul style={{ marginTop: 0 }}>
              <li>FEP</li>
              <li>The Experiment ID number (from Fabulous)</li>
              <li>If it’s Baseline or a Variation</li>
            </ul>

            <Box sx={{ p: 1, color: "green" }}>
              <strong>Example:</strong>
              <br />
              FEP_112_Baseline
            </Box>

            <Image src={step5} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 4</Typography>
            <Typography>
              Under <strong>Segment</strong> enter{" "}
              <strong>Dynamic variable</strong>
            </Typography>
            <Image src={step6} />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 5</Typography>
            <Typography>
              Under <strong>search for a dynamic var key</strong> enter:
            </Typography>
            <ul style={{ marginTop: 0 }}>
              <li>FEP_</li>
            </ul>
            And search for the correct ID of the experiment
            <Image src={step7} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 6</Typography>
            <Typography>
              Under <strong>search for a value</strong> you will find the
              different variations for the experiment. <br />
            </Typography>
            <Box sx={{ p: 1, color: "green" }}>
              <strong>Remember:</strong>
              <ul style={{ marginTop: 0 }}>
                <li>0 = Always Baseline</li>
                <li>1 = Variation 1</li>
                <li>2 = Variation 2</li>
                <li>... </li>
              </ul>
            </Box>

            <Image src={step8} />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 7</Typography>
            <Typography>
              Click <strong>Create</strong> to save as a segment so you can
              start using it in the different modules
            </Typography>
            <Box sx={{ p: 1, color: "green" }}>
              <strong>Remember:</strong>
              <br />
              Repeat the process to create another segment of the other
              variations
            </Box>

            <Image src={step9} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="button">Step 8</Typography>

            <Box sx={{ p: 1, color: "green" }}>
              <strong>Start analyzing in the different modules</strong>
            </Box>

            <Image src={step10} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentSquareSegments;
