import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DuplicateToRollout } from "../helpers/FetchData";
import {
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { useConfig } from "../context";

const DuplicateToRolloutButton: React.FC<any> = ({ item, closeParent }) => {
  const navigate = useNavigate();
  const [variation, setVariation] = useState<number>(-1);
  const [open, setOpen] = useState<boolean>(false);
  const { config, setConfig } = useConfig();
  const handleClose = () => {
    setOpen(false);
    closeParent();
  };

  const toggleBackdrop = (b: boolean) => {
    setConfig((prevState: any) => {
      return {
        ...prevState,
        showBackdrop: b,
      };
    });
  };
  const submitDuplicate = () => {
    toggleBackdrop(true);
    DuplicateToRollout(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
          toggleBackdrop(false);
        } else {
          enqueueSnackbar(`Rollout created!`, { variant: "success" });
          navigate(`/features/${item.flag_id}/${output.response.insertId}`);
          toggleBackdrop(false);
        }
      },
      Number(item.id),
      variation
    );
  };

  return (
    <ListItemButton
      disabled={config.user.level < 50 && item.type !== "ab"}
      onClick={() => setOpen(true)}
      sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}
    >
      <ListItemIcon>
        <ToggleOnIcon />
      </ListItemIcon>
      <ListItemText primary="Create rollout" />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create rollout from A/B-test</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This will duplicate the A/B-test and turn it into a rollout. Please
            select variation to rollout:
          </Typography>
          <FormControl
            sx={{ mt: 2, minWidth: 120, width: "100%" }}
            size="small"
          >
            <InputLabel id="variation_label">Variation</InputLabel>
            <Select
              value={variation}
              name="variation"
              size="small"
              onChange={(e: any) => setVariation(e.target.value)}
              labelId="variation"
              id="variation-select"
              label="Variation"
            >
              <MenuItem value="-1">Select variation</MenuItem>
              {item.variations
                .filter((vari: any) => vari.id > 0)
                .map((variation: any) => (
                  <MenuItem key={variation.id} value={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={variation < 1}
            onClick={submitDuplicate}
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </ListItemButton>
  );
};

export default DuplicateToRolloutButton;
