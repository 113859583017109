import React, { useState } from "react";

import { Grid, Alert } from "@mui/material";

import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DatepickerField: React.FC<any> = ({
  onChangeVariables,
  indexes,
  label,
  variable,
  disabled,
}) => {
  const currentValue = moment(variable.value, "YYYY-MM-DD HH:mm");
  const [startDate, setStartDate] = useState<Moment | null>(
    currentValue.isValid() ? moment(variable.value, "YYYY-MM-DD HH:mm") : null
  );

  const handler = (momentData: any) => {
    setStartDate(momentData);
    let formattedDate = null;
    if (momentData?.isValid() && momentData !== null) {
      const localTimeString = momentData.format('YYYY-MM-DD HH:mm');
      formattedDate = moment.utc(localTimeString, 'YYYY-MM-DD HH:mm').toISOString();
    }
    const fakeEvent = { target: { value: formattedDate, type: null } };
    onChangeVariables(fakeEvent, indexes);
  };

  const currentUtcTime = moment.utc().format("YYYY-MM-DD HH:mm");

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container style={{ marginTop: "5px" }}>
        <Grid item xs={3}>
          <DateTimePicker
            format="YYYY-MM-DD HH:mm"
            ampm={false}
            value={startDate}
            onChange={handler}
            label={label}
            disabled={disabled}
            slotProps={{
              actionBar: { actions: ["clear", "accept"] },
              shortcuts: {
                items: [
                  {
                    label: "Start of day",
                    getValue: () => {
                      return startDate
                        ? moment(startDate).startOf("day")
                        : null;
                    },
                  },
                  {
                    label: "End of day",
                    getValue: () => {
                      return startDate ? moment(startDate).endOf("day") : null;
                    },
                  },
                ],
              },
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Alert severity="info">
            You need to offset time according to your timezone. e.g. If stop at
            SWE-time 20.00, needs to be set to 19:00. <br /> Current UTC time
            is; <strong>{currentUtcTime}</strong>
          </Alert>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DatepickerField;
