import React from "react";

import { Link, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from "@mui/material/styles";

const HelpToolTip: React.FC<any> = ({ text, link }) => {
  const delay = link ? 500 : 200;
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: "0.75rem",
            fontWeight: 400,
            padding: "12px 14px",
            boxShadow: "0 2px 3px rgba(0,0,0,.1)",
            border: theme.palette.divider,
            color: theme.palette.text.primary,
            bgcolor: theme.palette.background.paper,
          },
        },
      }}
      sx={{ cursor: "pointer" }}
      leaveDelay={delay}
      title={
        <React.Fragment>
          {" "}
          {text}{" "}
          {link ? (
            <Link target="_blank" rel="noreferrer" href={link}>
              Read more
            </Link>
          ) : null}
        </React.Fragment>
      }
      placement="top"
    >
      <HelpOutlineIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} />
    </Tooltip>
  );
};

export default HelpToolTip;
