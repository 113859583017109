import React, { useEffect, useState, useRef } from "react";

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  IconButton,
  FormLabel,
  Alert,
  Collapse,
  Box,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

// @ts-ignore
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor/dist/jsoneditor.css";

import baseSiteWideBanner from "./baseSiteWideBanner";

const CustomCxsSiteWideBanner: React.FC<any> = ({
  onChangeVariables,
  indexes,
  variable,
}) => {
  const initialParsedVariable =
    variable.value.length !== 0
      ? JSON.parse(variable.value)
      : baseSiteWideBanner;
  const [siteBannerObj, setSiteBannerObj] = useState<any>(
    initialParsedVariable
  );

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  // Is to fix eslint dependency warning
  const indexesRef = useRef(indexes);
  const onChangeVariablesRef = useRef(onChangeVariables);
  useEffect(() => {
    indexesRef.current = indexes;
    onChangeVariablesRef.current = onChangeVariables;
  }, [indexes, onChangeVariables]);

  useEffect(() => {
    const value = JSON.stringify(siteBannerObj);
    const fakeEvent = { target: { value: value, type: null } };
    onChangeVariablesRef.current(fakeEvent, indexesRef.current);

    const appSiteBannerObj = manipulateObjToApp(siteBannerObj);

    const appValue = JSON.stringify(appSiteBannerObj);
    const appFakeEvent = { target: { value: appValue, type: null } };
    const appJsonIndex = {
      ...indexesRef.current,
      variIndex: 1,
      flagVariIndex: 1,
    }; // to target "fullSiteWideBannerApps"
    onChangeVariablesRef.current(appFakeEvent, appJsonIndex);
  }, [siteBannerObj]);

  const manipulateObjToApp = (obj: any) => {
    let newObj = { ...obj, labels: { ...obj.labels } };
    if (newObj.labels) {
      newObj = {
        ...newObj,
        headline: newObj.labels.headline,
        legalText: newObj.labels.legalText,
      };
      delete newObj.labels.headline;
      delete newObj.labels.legalText;

      // Rename collapsedCta to shopNow and expandedCta to close
      newObj.labels.shopNow = newObj.labels.collapsedCta;
      delete newObj.labels.collapsedCta;
      newObj.labels.close = newObj.labels.expandedCta;
      delete newObj.labels.expandedCta;
    }
    return newObj;
  };

  const jsonHandler = (value: any): void => {
    setSiteBannerObj(value);
  };

  const ctaHandler = (event: any, ctaIndex: number) => {
    const { name, value } = event.target;
    const updatedLinks = siteBannerObj.links.map((cta: any, index: number) => {
      if (index === ctaIndex) {
        let updatedCta = { ...cta, [name]: value };
        if (name === "targetPath") {
          let transformedValue = value;
          if (value.includes("/content/hmonline")) {
            transformedValue = value.replace("/content/hmonline", "") + ".html";
          }
          updatedCta = {
            ...updatedCta,
            path: transformedValue,
            aliasPath: transformedValue,
          };
        }
        return updatedCta;
      }
      return cta;
    });
    setSiteBannerObj({ ...siteBannerObj, links: updatedLinks });
  };

  const removeCta = (ctaIndex: number) => {
    const updatedLinks = siteBannerObj.links.filter(
      (_: any, index: number) => index !== ctaIndex
    );
    setSiteBannerObj({ ...siteBannerObj, links: updatedLinks });
  };

  const addCta = () => {
    const newCta = {
      title: "",
      path: "",
      aliasPath: "",
      targetPath: "",
      targetTemplate: "/apps/hm/templates/content/department",
      trackingActivityCode: "fabulous-abtest-sitewidebanner-cta",
      trackingActivityType: "sitewidebanner",
    };

    const updatedLinks = [...siteBannerObj.links, newCta];
    setSiteBannerObj({ ...siteBannerObj, links: updatedLinks });
  };

  const CtaField = () => {
    const fields = [
      {
        name: "path",
        label: "Path",
        hide: true,
      },
      {
        name: "aliasPath",
        label: "Alias Path",
        hide: true,
      },
      {
        name: "title",
        label: "Title",
      },
      {
        name: "targetPath",
        label: "Target Path",
      },
      // {
      //   name: "targetTemplate",
      //   label: "Target Template",
      // },
    ];

    const targetTemplate = {
      name: "targetTemplate",
      label: "Target Template",
      options: [
        { value: "/apps/hm/templates/content/department", label: "Department" },
        {
          value: "/apps/hm/templates/content/subdepartment",
          label: "Subdepartment",
        },
        {
          value: "/apps/hm/templates/content/landingpage",
          label: "Landingpage",
        },
        { value: "/apps/hm/templates/content/giftcard", label: "Giftcard" },
        { value: "/apps/hm/templates/content/brandpage", label: "Brandpage" },
        {
          value: "/apps/hm/templates/content/editorialcampaignpage",
          label: "Editorialcampaignpage",
        },
        {
          value: "/apps/hm/templates/content/scrollcampaignpage",
          label: "Scrollcampaignpage",
        },
        { value: "", label: "For external links" },
      ],
    };

    return (
      <Grid item xs={12} key="grid-cta-field">
        <Grid key="cta-field" container spacing={1} sx={{ marginBottom: 1 }}>
          {siteBannerObj.links?.map((cta: any, ctaIndex: number) => {
            return (
              <Grid item xs={6} key={"cta" + ctaIndex}>
                <FormLabel id="demo-radio-buttons-group-label">
                  CTA {ctaIndex + 1}:
                </FormLabel>
                <br />
                {fields.map((field) =>
                  field.hide ? null : (
                    <TextField
                      key={field.name + ctaIndex}
                      label={field.label}
                      name={field.name}
                      value={cta[field.name] || ""}
                      sx={{ minWidth: "150px", marginRight: 1 }}
                      style={{ width: "calc(50% - 30px)" }}
                      margin="dense"
                      onChange={(event) => ctaHandler(event, ctaIndex)}
                    />
                  )
                )}
                <FormControl
                  sx={{ minWidth: "calc(50% - 30px)", marginRight: 1 }}
                  margin="dense"
                >
                  <InputLabel>{targetTemplate.label}</InputLabel>
                  <Select
                    value={cta[targetTemplate.name] || ""}
                    onChange={(event) => ctaHandler(event, ctaIndex)}
                    label={targetTemplate.label}
                    name={targetTemplate.name}
                  >
                    {targetTemplate.options.map((item) => (
                      <MenuItem
                        key={`target-template-${item.label}`}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  aria-label="delete"
                  name="cta"
                  onClick={() => removeCta(ctaIndex)}
                  style={{ margin: "16px 0 0px 5px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={() => addCta()}>
              Add CTA
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const labelsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSiteBannerObj((prevState: any) => ({
      ...prevState,
      labels: {
        ...prevState.labels,
        [name]: value,
      },
    }));
  };

  const LabelField = () => {
    const fields = [
      {
        name: "headline",
        label: "Headline",
      },
      {
        name: "legalText",
        label: "Legal Text",
      },
      {
        name: "expandedCta",
        label: "Expanded CTA",
      },
      {
        name: "collapsedCta",
        label: "Collapsed CTA",
      },
    ];

    if (!siteBannerObj.labels) return null;

    return (
      <Grid item xs={12} key="grid-lable-field">
        <Grid key="lable-field" container>
          {fields.map((field, fieldIndex) => (
            <TextField
              key={field.name + fieldIndex}
              label={field.label}
              name={field.name}
              value={siteBannerObj.labels[field.name] || ""}
              sx={{ minWidth: "150px", marginRight: 1 }}
              style={{ width: "calc(50% - 30px)" }}
              margin="dense"
              onChange={labelsHandler}
              multiline={field.name === "legalText"}
              rows={field.name === "legalText" ? 4 : undefined}
            />
          ))}
        </Grid>
      </Grid>
    );
  };

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setSiteBannerObj((prevState: any) => ({
      ...prevState,
      [name!]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const dropdownField = ({
    label,
    name,
    arr,
  }: {
    label: string;
    name: string;
    arr: string[];
  }) => {
    return (
      <FormControl
        sx={{ minWidth: "calc(50% - 30px)", marginRight: 1 }}
        margin="dense"
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={
            siteBannerObj[name] === false ? "false" : siteBannerObj[name] || ""
          }
          onChange={changeHandler}
          label={label}
          name={name}
        >
          {arr.map((item) => (
            <MenuItem key={`menu-item-${item}`} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const endDateHandler = (event: any) => {
    const { name, value } = event.target;
    let value2 = value;

    if (value === "") {
      value2 =
        new Date(new Date().setDate(new Date().getDate() + 7))
          .toISOString()
          .split(".")[0] + "Z";
    }

    setSiteBannerObj((prevState: any) => ({
      ...prevState,
      [name!]: value2,
    }));
  };

  return (
    <>
      {LabelField()}
      <TextField
        key={"endDate"}
        label={"End Date"}
        name={"endDate"}
        value={siteBannerObj.endDate || ""}
        sx={{ minWidth: "150px", marginRight: 1 }}
        style={{ width: "calc(50% - 30px)" }}
        margin="dense"
        onChange={endDateHandler}
      />
      {dropdownField({
        label: "Palette",
        name: "palette",
        arr: [
          "default",
          "inverted",
          "sale",
          "member",
          "deals",
          "blackFriday",
          "cyberMonday",
        ],
      })}
      {dropdownField({
        label: "Should Show Cta",
        name: "shouldShowCta",
        arr: ["true", "false"],
      })}
      {dropdownField({
        label: "Legal Text Placement",
        name: "legalTextPlacement",
        arr: ["expanded", "infoIcon"],
      })}
      <br /> <br />
      {CtaField()}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="text" onClick={toggleEditorVisibility}>
          {isEditorVisible
            ? "Hide advanced developer editor"
            : "Open advanced developer editor"}
        </Button>
      </Box>
      <Collapse in={isEditorVisible}>
        <Alert severity="warning">
          If you made updates above, they will not be reflected beneath. Save
          your changes and refresh before editing the JSON.
        </Alert>
        <JsonEditor
          value={siteBannerObj}
          onChange={jsonHandler}
          mode="code"
          htmlElementProps={{ style: { height: 400 } }}
        />
      </Collapse>
    </>
  );
};

export default CustomCxsSiteWideBanner;
