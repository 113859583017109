const nsTeaserFields = [
  {
    label: "Palette",
    options: {
      choices: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "inverted",
          label: "Inverted",
        },
        {
          value: "sale",
          label: "Sale",
        },
        {
          value: "member",
          label: "Member",
        },
        {
          value: "deals",
          label: "Deals",
        },
        {
          value: "blackFriday",
          label: "Black Friday",
        },
        {
          value: "cyberMonday",
          label: "Cyber Monday",
        },
      ],
    },
    field_type: "dropdown",
    name: "palette",
    value: "",
  },
  {
    label: "Headline",
    options: null,
    field_type: "multi_text",
    name: "headline",
    value: "",
  },
  {
    label: "Headline size",
    options: {
      choices: [
        { value: "small", label: "Small" },
        { value: "large", label: "Large" },
      ],
    },
    field_type: "dropdown",
    name: "headlineSize",
    value: "",
  },
  {
    label: "Legal title",
    options: null,
    field_type: "multi_text",
    name: "legalTitle",
    value: "",
  },
  {
    label: "Legal text",
    options: null,
    field_type: "multi_text",
    name: "legalText",
    value: "",
  },
  {
    label: "Sale title",
    options: null,
    field_type: "multi_text",
    name: "saleTitle",
    value: "",
  },
  {
    label: "Secondary title",
    options: null,
    field_type: "multi_text",
    name: "secondaryTitle",
    value: "",
  },
  {
    label: "Subtitle",
    options: null,
    field_type: "multi_text",
    name: "subtitle",
    value: "",
  },
  {
    label: "CTA",
    options: null,
    field_type: "ctas",
    name: "ctas",
    value: [{ url: "link", text: "cta" }],
  },
  {
    label: "Variant",
    options: {
      choices: [
        { value: "stage", label: "stage" },
        { value: "fullbleed", label: "fullbleed" },
        { value: "indent", label: "indent" },
        { value: "sale", label: "sale" },
      ],
    },
    field_type: "dropdown",
    name: "variant",
    value: "",
  },
  {
    label: "countdownDate",
    options: null,
    field_type: "multi_text",
    name: "countdownDate",
    value: "2024-08-19T07:18:00Z",
  },
  {
    label: "countdownText",
    options: null,
    field_type: "multi_text",
    name: "countdownText",
    value: "FLASH DEAL - {0}:{1}:{2}:{3}",
  },
  {
    label: "Asset type",
    options: {
      choices: [
        {
          value: "video",
          label: "Video",
        },
        {
          value: "image",
          label: "Image",
        }
      ],
    },
    field_type: "dropdown",
    name: "assetType",
    value: "",
  },
  {
    label: "Background image url",
    options: null,
    field_type: "multi_text",
    name: "image",
    value: "",
    belongsTo: "fourFiveRatio",
  },
  {
    label: "Background video url",
    options: null,
    field_type: "multi_text",
    name: "video",
    value: "",
    belongsTo: "fourFiveRatio",
  },
];

export default nsTeaserFields;
