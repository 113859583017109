import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { UpdateFlag } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import AddEditVariables from "./AddEditVariables";
import SetArchiveFlag from "./SetArchiveFlag";
import UnArchiveFlag from "./UnArchiveFlag";
import { useConfig } from "./../context";

const EditFlag: React.FC<{
  flagId: number;
  flagData: any;
  submitDisabled: boolean;
}> = (props) => {
  const { flagId, flagData, submitDisabled } = props;
  const { config } = useConfig();

  const [open, setOpen] = useState(true);
  const [formState, setFormState] = useState({
    name: "",
    description: "",
    flag_key: "",
    state: 0,
    archived: 0,
    brand_id: 0,
    team_id: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // FetchConfiguration((output: ApiResponse) => {
    //   setConfiguration(output);
    // });
  }, []);

  useEffect(() => {
    setFormState({
      name: flagData.name,
      flag_key: flagData.flag_key,
      description: flagData.description,
      state: flagData.state,
      archived: flagData.state,
      brand_id: flagData.brand_id,
      team_id: flagData.team_id,
    });
  }, [flagData]);

  const handleClick = () => {
    setOpen(!open);
  };

  const submitForm = () => {
    setLoading(true);

    UpdateFlag(
      (output: ApiResponse) => {
        setLoading(false);
      },
      flagId,
      formState
    );
  };

  const onChangeHandler = (e: any) => {
    const { name, value, type } = e.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);
    setFormState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  return (
    <div>
      {formState.name !== "" && (
        <>
          <Accordion expanded={open}>
            <AccordionSummary
              onClick={handleClick}
              expandIcon={<ExpandMoreIcon />}
            >
              <div className="variation-accordion-item-header">
                <h2 style={{ display: "inline" }}>Edit feature</h2>{" "}
                <span style={{ fontSize: "0.8rem" }}>
                  {!open ? "Click to expand" : "Click to collapse"}
                </span>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <form onSubmit={submitForm}>
                <Grid container spacing={3} sx={{ padding: "10px 40px 30px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    value={formState.name}
                    name="name"
                    label="Feature name"
                    type="text"
                    fullWidth
                    onChange={onChangeHandler}
                  />
                  <TextField
                    margin="dense"
                    value={formState.flag_key}
                    name="flag_key"
                    label="Feature key"
                    type="text"
                    fullWidth
                    disabled={true}
                    onChange={onChangeHandler}
                  />
                  <TextField
                    margin="dense"
                    value={formState.description}
                    name="description"
                    label="Feature description"
                    type="text"
                    fullWidth
                    onChange={onChangeHandler}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs="auto">
                      <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">
                          Team
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="team_id"
                          label="Team"
                          disabled={true}
                          defaultValue={formState.team_id}
                        >
                          <MenuItem value={0}>Choose your team</MenuItem>
                          {config.teams?.map((team: any) => (
                            <MenuItem value={team.id} key={team.id}>
                              {team.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel id="brand-select-label">Brand</InputLabel>
                        <Select
                          labelId="brand-select-label"
                          id="brand-select"
                          name="brand_id"
                          label="Team"
                          disabled={true}
                          defaultValue={formState.brand_id}
                        >
                          <MenuItem value={0}>Choose brand</MenuItem>
                          {config.brands?.map((brand: any) => (
                            <MenuItem value={brand.id} key={brand.id}>
                              {brand.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                </Grid>
                <Divider />
                <Grid container justifyContent="space-between" sx={{ p: 2 }}>
                  <Grid item>
                    {flagData.archived === 1 && config.user.level === 100 ? (
                      <UnArchiveFlag flagId={flagId} />
                    ) : (
                      <SetArchiveFlag
                        flagId={flagId}
                        hasLiveRules={flagData.rules_in_prod}
                        disabled={
                          config.user.level < 25 ||
                          config.user.region.length > 0 ||
                          flagData?.archived === 1
                        }
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={submitForm}
                      disabled={submitDisabled}
                    >
                      {loading ? <CircularProgress /> : "Update"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={!open}>
            <AccordionSummary
              onClick={handleClick}
              expandIcon={<ExpandMoreIcon />}
            >
              <div className="variation-accordion-item-header">
                <h2 style={{ display: "inline" }}>Edit variables</h2>{" "}
                <span style={{ fontSize: "0.8rem" }}>
                  {open ? "Click to expand" : "Click to collapse"}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <AddEditVariables
                flagData={flagData}
                submitDisabled={submitDisabled}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

export default EditFlag;
