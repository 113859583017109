import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Chip, CircularProgress } from "@mui/material";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { useConfig } from "../context";
import { formatAudienceMatchType } from "../helpers/Helpers";

export const DetailsTable = (props: {
  textColor: string;
  start_time: string;
  end_time: string;
  state: number;
  bucketedVisitors: { total: number; variations: [] };
  attributes: [];
  decision_page: string;
  id: number;
}) => {
  const { config } = useConfig();

  const getDaysRunning = () => {
    const start = props.start_time;
    const end = moment();
    return Math.round(moment.duration(end.diff(start)).asDays());
  };

  return (
    <TableContainer>
      <Table aria-label="a dense table">
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ fontSize: "12px", padding: "10px" }}
            >
              Start:
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "12px", padding: "10px" }}>
              {moment(props.start_time).isValid()
                ? moment(props.start_time).format("YYYY-MM-DD HH:mm")
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{ fontSize: "12px", padding: "10px" }}
            >
              Stop:
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "12px", padding: "10px" }}>
              {props.state === 30 ? (
                <>
                  <span style={{ color: "#7FBD32" }}>Running </span>(
                  {getDaysRunning()} days)
                </>
              ) : moment(props.end_time).isValid() ? (
                moment(props.end_time).format("YYYY-MM-DD HH:mm")
              ) : (
                "-"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                fontSize: "12px",
                padding: "10px",
                width: "auto",
              }}
            >
              Users in experiment:
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "12px", padding: "10px" }}>
              {props.bucketedVisitors.total === 0 ? (
                <CircularProgress size={8} color="inherit" />
              ) : props.bucketedVisitors.total === -1 ? (
                "-"
              ) : (
                Intl.NumberFormat().format(props.bucketedVisitors.total)
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                fontSize: "12px",
                padding: "10px",
                width: "auto",
              }}
            >
              Audience:
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "12px", padding: "10px" }}>
              {props.attributes.map((item: any, index) => (
                <Tooltip
                  key={index}
                  placement="top"
                  enterDelay={200}
                  title={`${item.attribute} ${formatAudienceMatchType(
                    item.matchType
                  )} ${item.values.join(", ")}`}
                >
                  <Chip
                    sx={{
                      cursor: "help",
                      fontSize: "10px",
                      margin: "0 2px 2xp",
                      height: "16px",
                      padding: 0,
                    }}
                    label={item.name}
                    size="small"
                  />
                </Tooltip>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: "12px", padding: "10px" }}>
              Target:
            </TableCell>
            <TableCell align="right" sx={{ ontSize: "12px", padding: "10px" }}>
              {config.decision_pages
                ? config.decision_pages.find(
                    (obj) => obj.id === props.decision_page
                  )?.name
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: "12px", padding: "10px" }}>
              Experiment-ID:
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "12px", padding: "10px" }}>
              {props.id}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
