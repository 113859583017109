import React, { useState, useEffect } from "react";
import { FetchStatsLaunchedByBrand } from "../helpers/FetchData";
import ColorPalette from "../helpers/ColorPalette";
import { useConfig } from "../context";
import LaunchedByGraph from "./LaunchedByGraph";

const LaunchedByBrand: React.FC<any> = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchData, setLaunchData] = useState([]);
  const { config } = useConfig();
  const [brandsInChart, setBrandsInChart] = useState<any[]>([]);
  const [chartColors, setChartColors] = useState<any>([]);

  useEffect(() => {
    if (config.brands.length > 0) {
      FetchStatsLaunchedByBrand((output: any) => {
        setIsLoaded(true);
        const all: any = [];
        const allBrands: any = [];
        output.response.forEach((item: any) => {
          const obj = all.find((a: any) => a.month === item.month) || {
            month: item.month,
          };
          const brand: any = config.brands.find(
            (t: any) => t.id === item.brand_id
          );
          obj[brand.brand_key] = item.experiments_started;
          if (!allBrands.includes(brand.brand_key)) {
            allBrands.push(brand.brand_key);
          }
          const index: number = all.findIndex(
            (a: any) => a.month === item.month
          );
          if (index === -1) {
            all.push(obj);
          } else {
            all[index] = obj;
          }
        });
        setLaunchData(all);
        setBrandsInChart(allBrands);
        const palette = ColorPalette(allBrands.length);
        const colors: any = [];
        allBrands.forEach((brand: any, idx: number) => {
          colors[brand] = palette(idx);
        });
        setChartColors(colors);
      });
    }
  }, [config]);

  const getBrandName = (brand_key: string) => {
    const brand: any = config.brands.find(
      (t: any) => t.brand_key === brand_key
    );
    return brand ? brand.name : brand_key;
  };
  const tooltipFormatter = (value: any, name: any) => {
    return [value, getBrandName(name)];
  };

  const legendFormatter = (value: string, entry: any) => {
    const { color } = entry;
    return <span style={{ color }}>{getBrandName(value)}</span>;
  };

  return (
    <LaunchedByGraph
      title="Experiments by Brand"
      isLoaded={isLoaded}
      data={launchData}
      tooltipFormatter={tooltipFormatter}
      itemsInChart={brandsInChart}
      legendFormatter={legendFormatter}
      chartColors={chartColors}
    />
  );
};

export default LaunchedByBrand;
