import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { UpdateFlagVariables } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";

const AddEditVariables: React.FC<{ flagData: any; submitDisabled: boolean }> = (
  props
) => {
  let variabelTypes = [
    { id: "string", name: "String" },
    //{ id: "number", name: "Number" },
    { id: "boolean", name: "Boolean" },
    { id: "json", name: "JSON" },
    { id: "custom", name: "Custom" },
  ];

  const { flagData, submitDisabled } = props;

  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState(flagData.variables);

  // Check forumState if their type exists in variabelTypes, if not, add it to variabelTypes
  if (formState) {
    formState.forEach((item: any) => {
      if (!variabelTypes.some((type: any) => type.id === item.type)) {
        variabelTypes.push({ id: item.type, name: item.type });
      }
    });
  }

  const submitVariablesForm = () => {
    setLoading(true);

    const varKeys = formState.map((item: { var_key: string }) => item.var_key);
    const hasDuplicates = varKeys.some(
      (item: any, index: number) => varKeys.indexOf(item) !== index
    );

    if (hasDuplicates) {
      enqueueSnackbar("You can't have variables with the same key name.", {
        variant: "error",
      });
      setLoading(false);
      return;
    }

    const variables = {
      flag_id: flagData.id,
      variables: formState,
    };

    UpdateFlagVariables(
      (output: ApiResponse) => {
        enqueueSnackbar("Variables updated.", { variant: "success" });
        setLoading(false);
      },
      flagData.id,
      variables
    );

    /*
		ToDo?
		- So you can update the Default Value and Type? But not var_key
		- Beeing able to delete a variabel? (or put to archive = true)

		*/
  };

  const onChangeHandler = (e: any, index: number) => {
    let { name, value } = e.target;
    if (name === "var_key") {
      value = value.replace(/ /g, "_");
    }

    if (name === "type" && value === "custom") {
      const customValue = prompt("Please enter a custom type");
      value = customValue || "string";
    }

    setFormState((prevState: any) =>
      prevState.map((item: any, key: number) => {
        if (index !== key) return { ...item };
        return { ...item, [name]: value };
      })
    );
  };

  const addNewVariables = () => {
    setFormState((prevState: any) => {
      if (prevState) {
        return [...prevState, { default_value: "", type: "string" }];
      }
      return [{ default_value: "", type: "string" }];
    });
  };

  return (
    <form onSubmit={submitVariablesForm}>
      <Grid container spacing={3} sx={{ padding: "0 15px 15px" }}>
        {formState?.map((variables: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  value={variables.var_key || ""}
                  name="var_key"
                  label="Key"
                  type="text"
                  fullWidth
                  onChange={(e) => onChangeHandler(e, index)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl sx={{ mt: 1, minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    name="type"
                    label="Type"
                    margin="dense"
                    value={variables.type || ""}
                    onChange={(e) => onChangeHandler(e, index)}
                  >
                    {variabelTypes.map((type: any) => (
                      <MenuItem value={type.id} key={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  autoFocus
                  margin="dense"
                  value={variables.default_value || ""}
                  name="default_value"
                  label="Default value (optional)"
                  type="text"
                  fullWidth
                  onChange={(e) => onChangeHandler(e, index)}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Divider />
      <Grid container justifyContent="space-between" sx={{ p: 2 }}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={addNewVariables}
            disabled={submitDisabled}
          >
            Add variable
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={submitVariablesForm}
            disabled={
              submitDisabled ||
              (formState &&
                formState.some(
                  (i: any) =>
                    !i.var_key || (i.var_key && i.var_key.length === 0)
                ))
            }
          >
            {loading ? <CircularProgress /> : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEditVariables;
