import * as Msal from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { getRedirectUri } from "./Helpers";

const publicClientApplication = new Msal.PublicClientApplication(msalConfig);
(window as any).msal = publicClientApplication;
const msalToken = async (callback: any) => {
  const redirectResponse =
    await publicClientApplication.handleRedirectPromise();
  let accessToken = "";

  if (redirectResponse !== null) {
    // Acquire token silent success
    accessToken = redirectResponse.idToken;
    callback(accessToken);
  } else {
    // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
    const account = publicClientApplication.getAllAccounts()[0];
    const redirectUri = getRedirectUri();
    const accessTokenRequest = {
      scopes: ["openid"],
      account: account,
      redirectUri: redirectUri + "/blank.html"
    };


    publicClientApplication
      .acquireTokenSilent(accessTokenRequest)
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        accessToken = accessTokenResponse.idToken;
        callback(accessToken);
      })
      .catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        if (error instanceof Msal.InteractionRequiredAuthError) {
          accessTokenRequest.redirectUri = redirectUri;
          publicClientApplication.acquireTokenRedirect(accessTokenRequest);
        }
      });
  }
  return accessToken;
};

export { msalToken, publicClientApplication };
