import React, { useState, useEffect } from "react";
import { FetchConfiguration, FetchCms } from "./helpers/FetchData";
import { cms_strings } from "./CMS/cms_strings";
import { Backdrop, CircularProgress } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";

type MyData = {
  teams: [];
  regions: [];
  rule_states: [];
  metric_categories: [];
  decision_pages: any[];
  department_filters: [DepartmentFilter];
  metric_directions: [];
  metric_types: [];
  showBackdrop: false;
  user: UserObject;
  guardrail_metrics: [];
  cms_strings: any;
  siteMessage: string;
  touchpoints: [];
  darkMode: boolean;
  brands: [];
  tokenExpired: boolean;
  generatedContent: { input: string; output: [] };
  rule_version: number;
};

type ConfigType = {
  config: MyData;
  setConfig: React.Dispatch<React.SetStateAction<MyData>>;
};

type UserObject = {
  email: "";
  group: "";
  level: number | 0;
  region: "";
  role: "";
  userId: 0;
};

type DepartmentFilter = {
  id: number;
  name: string;
};

const Context = React.createContext<ConfigType>({} as ConfigType);
const overrideUser =
  process.env.REACT_APP_ENV !== "production" &&
  localStorage.getItem("fabulousUser")
    ? localStorage.getItem("fabulousUser")
    : false;

const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState({} as MyData);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setConfig((prevState) => {
      return {
        ...prevState,
        cms_strings: cms_strings,
        darkMode: false,
        generatedContent: { input: "", output: [] },
      };
    });
    FetchConfiguration((output: any) => {
      const sortedTeams = [...output.response.teams].sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
      if (overrideUser) {
        output.response.user = JSON.parse(overrideUser);
      }
      const sorted = {
        ...output,
        response: { ...output.response, teams: sortedTeams },
      };
      setConfig((prevState) => {
        return { ...prevState, ...sorted.response };
      });
    });
    FetchCms((output: any) => {
      setConfig((prevState) => {
        return { ...prevState, cms_strings: output.response };
      });
    });
  }, []);

  useEffect(() => {
    if (config.cms_strings && config.teams) {
      setConfigLoaded(true);
    }
  }, [config]);

  useEffect(() => {
    const tokenChecker = setInterval(() => {
      const token = sessionStorage.getItem("fabulousTokenExpired");
      if (token) {
        sessionStorage.removeItem("fabulousTokenExpired");
        setTokenExpired(true);
        setConfig((prevState) => {
          return { ...prevState, tokenExpired: true };
        });
      }
      return () => clearInterval(tokenChecker);
    }, 5000);
  }, []);
  return (
    <Context.Provider value={{ config, setConfig }}>
      {tokenExpired || configLoaded || !isAuthenticated ? (
        children
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Context.Provider>
  );
};

export default ConfigProvider;

export const useConfig = () => React.useContext(Context);
