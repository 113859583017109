import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Link,
} from "@mui/material";
import CustomFilter from "./CustomFilter";
import { useConfig } from "./../context";

const CustomMetricDialog: React.FC<any> = ({
  customMetricOpen,
  customMetricClose,
  metricIndex,
  rule,
  setRule,
  editDisabled,
}) => {
  const { config } = useConfig();
  const [currentFilterTabIndex, setCurrentFilterTabIndex] = useState(0);
  const [filter, setFilter] = useState([
    { pointer: "", value: "", matching: "" },
  ]);
  const [customMetric, setCustomMetric] = useState({
    name: "",
    type: "prop",
    direction: "greater",
    event: "",
    winning_direction: "positive",
    custom: true,
    pointer: null,
    currency: "false",
    custom_filter: filter,
    filter: null,
    index: -2,
  });

  useEffect(() => {
    const currentMetric = metricIndex > -1 ? rule.metrics[metricIndex] : false;

    if (currentMetric) {
      setCustomMetric(currentMetric);
      if (currentMetric.custom_filter) {
        setFilter(currentMetric.custom_filter);
      } else {
        setFilter([{ pointer: "", value: "", matching: "" }]);
      }
    } else {
      setFilter([{ pointer: "", value: "", matching: "" }]);
      setCustomMetric({
        name: "",
        type: "prop",
        direction: "greater",
        event: "",
        winning_direction: "positive",
        custom: true,
        pointer: null,
        currency: "false",
        custom_filter: filter,
        filter: null,
        index: rule.metrics.length - 1,
      });
    }

    setDialogOpen(customMetricOpen);
  }, [customMetricOpen, metricIndex, rule.metrics]); // eslint-disable-line react-hooks/exhaustive-deps

  const [dialogOpen, setDialogOpen] = useState(customMetricOpen);

  const handleFilterTabChange = (e: any, tabIndex: number) => {
    setCurrentFilterTabIndex(tabIndex);
  };
  const customMetricHandler = (e: any) => {
    const { name, value } = e.target;
    setCustomMetric((prevState: any) => {
      const data = { ...prevState, [name]: value };
      return data;
    });
  };

  const addCustomMetricWrapper = (e: any) => {
    const hasCustomFilter: boolean = filter.some(
      (i: any) => i.matching.length > 0
    );
    setRule((prevState: any) => {
      if (metricIndex === -1) {
        return {
          ...prevState,
          metrics: [
            ...prevState.metrics,
            {
              ...customMetric,
              custom_filter: hasCustomFilter ? filter : null,
              index: prevState.metrics.length,
            },
          ],
        };
      } else {
      }
      const metrics = prevState.metrics.map((item: any, key: any) => {
        if (key === metricIndex) {
          return {
            ...item,
            name: customMetric.name,
            type: customMetric.type,
            direction: customMetric.direction,
            event: customMetric.event,
            winning_direction: customMetric.winning_direction,
            custom: true,
            pointer: customMetric.pointer,
            currency: customMetric.currency,
            custom_filter: hasCustomFilter ? filter : null,
          };
        } else {
          return item;
        }
      });
      return { ...prevState, metrics: metrics };
    });
    customMetricClose();
  };

  const [enableSubmit, setEnableSubmit] = useState(() => {
    const alteredFilter = filter.some(
      (i) => i.pointer.length > 0 || i.matching.length > 0 || i.value.length > 0
    );
    return alteredFilter
      ? filter.some(
          (i) =>
            i.pointer.length > 0 && i.matching.length > 0 && i.value.length > 0
        )
      : true;
  });

  const checkSubmit = useCallback(() => {
    const alteredFilter = filter.some(
      (i) => i.pointer.length > 0 || i.matching.length > 0 || i.value.length > 0
    );
    const shouldEnable = alteredFilter
      ? filter.some(
          (i) =>
            i.pointer.length > 0 && i.matching.length > 0 && i.value.length > 0
        )
      : true;
    setEnableSubmit(shouldEnable);
  }, [filter]);

  useEffect(() => {
    checkSubmit();
  }, [checkSubmit]);

  const customMetricView = () => {
    return (
      <Grid
        justifyContent={"stretch"}
        alignItems="bottom"
        container
        className="custom-metrics-form"
        sx={{ pt: 2 }}
      >
        <Grid item xs={12} sx={{ minHeight: "auto!important" }}>
          <Typography sx={{ p: 2, fontStyle: "italic" }} variant="body2">
            {config.cms_strings.metrics.custom_metric_info}
            <Link
              target="_blank"
              rel="noreferrer"
              href={config.cms_strings.metrics.custom_metric_info_link}
            >
              Read more
            </Link>
            .{" "}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl sx={{ width: "100%" }} size="small">
            <TextField
              autoFocus
              margin="dense"
              value={customMetric.name}
              name="name"
              label="Metric name"
              type="text"
              fullWidth
              autoComplete="off"
              onChange={customMetricHandler}
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.metric_name_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl sx={{ width: "100%" }} size="small">
            <TextField
              margin="dense"
              value={customMetric.event}
              name="event"
              label="Event"
              type="text"
              fullWidth
              autoComplete="off"
              onChange={customMetricHandler}
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.event_name_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{}}>
          <FormControl sx={{ width: "100%", mt: 1 }} size="small">
            <InputLabel id="">Test direction</InputLabel>
            <Select
              onChange={customMetricHandler}
              value={customMetric.direction}
              name="direction"
              label="Test direction"
            >
              {config.metric_directions.map((item: any, key: any) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.test_direction_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{}}>
          <FormControl sx={{ width: "100%", mt: 1 }} size="small">
            <InputLabel id="">Winning direction</InputLabel>
            <Select
              value={customMetric.winning_direction}
              onChange={customMetricHandler}
              name="winning_direction"
              label="Winning direction"
            >
              <MenuItem key={0} value="positive">
                positive
              </MenuItem>
              <MenuItem key={1} value="negative">
                negative
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.winning_direction_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{}}>
          <FormControl sx={{ width: "100%", mt: 1 }} size="small">
            <InputLabel id="">Test type</InputLabel>
            <Select
              value={customMetric.type}
              onChange={customMetricHandler}
              name="type"
              label="Test type"
            >
              {config.metric_types.map((item: any, key: any) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.test_type_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{}}>
          <FormControl sx={{ width: "100%", mt: 1 }} size="small">
            <InputLabel id="">Currency</InputLabel>
            <Select
              value={customMetric.currency}
              onChange={customMetricHandler}
              name="currency"
              label="Currency"
            >
              <MenuItem key={0} value="false">
                false
              </MenuItem>
              <MenuItem key={1} value="true">
                true
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.currency_tooltip}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{}}>
          <FormControl sx={{ width: "100%" }} size="small">
            <TextField
              margin="dense"
              value={customMetric.pointer || ""}
              name="pointer"
              label="Pointer"
              type="text"
              fullWidth
              autoComplete="off"
              onChange={customMetricHandler}
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ pl: 1 }}>
          <Typography variant="caption">
            {config.cms_strings.metrics.pointer_tooltip}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      open={dialogOpen}
      onClose={customMetricClose}
      fullWidth={false}
      maxWidth="md"
    >
      <DialogTitle>Configure custom metric</DialogTitle>
      <DialogContent>
        <Tabs
          value={currentFilterTabIndex}
          onChange={handleFilterTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Settings" />
          <Tab label="Custom filters" disabled={config.user.level < 50} />
        </Tabs>
        {currentFilterTabIndex === 0 ? (
          customMetricView()
        ) : (
          <CustomFilter filter={filter} setFilter={setFilter} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={customMetricClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={addCustomMetricWrapper}
          data-metric={JSON.stringify(customMetric)}
          disabled={
            customMetric.name.length < 2 ||
            customMetric.event.length < 2 ||
            !enableSubmit ||
            editDisabled
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomMetricDialog;
